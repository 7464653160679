<template>
  <div class="wrapper-login">
    <form class="login-form" @submit.prevent="onSubmit">
      <div v-if="loginStatus === 'loading'" style="padding: 135px 0">
        <SpinnerLoader :loading="loginStatus" />
      </div>
      <div v-else>
        <div class="mx-auto mb-5">
          <div>
            <img src="assets/images/logo.png" alt="" height="24" />
            <h2 class="d-inline align-middle ml-1 text-logo">
              <span class="danger-color">Open</span>
              <span>MediaLogic</span>
            </h2>
          </div>
        </div>
        <h6 class="h5 mb-0 mt-4">{{ $t('login.welcome') }}</h6>
        <p class="text-muted mt-1 mb-4">{{ $t('login.text') }}</p>
        <b-form-group :label="$t('login.yourEmail')" label-for="input-1-login" class="mb-1">
          <b-form-input id="input-1-login" v-model.trim="$v.form.login.$model" type="text" :placeholder="$t('login.enterEmail')" autofocus></b-form-input>
          <small :class="{ 'show-error-helper': $v.form.login.$invalid }" tabindex="-1" class="helper form-text text-muted">
            {{ !$v.form.login.required ? $t('login.email') : '' }} &nbsp;
            {{ !$v.form.login.minLength && $v.form.login.required ? $t('login.minLength', [1]) : '' }}
          </small>
        </b-form-group>

        <div role="group" class="form-group mb-0">
          <label for="input-2-password" class="d-block">{{ $t('login.yourPassword') }}</label>
          <div class="position-relative">
            <button class="btn-transparent password-eye" type="button">
              <b-icon :icon="isOpenPassword ? 'eye' : 'eye-slash'" font-scale="1" @click="isOpenPassword = !isOpenPassword"></b-icon>
            </button>
            <input
              id="input-2-password"
              v-model.trim="$v.form.password.$model"
              :type="isOpenPassword ? 'password' : 'text'"
              :placeholder="$t('login.enterPassword')"
              class="form-control"
            />
          </div>
        </div>
        <small :class="{ 'show-error-helper': $v.form.password.$invalid }" tabindex="-1" class="helper form-text text-muted mb-3">
          {{ !$v.form.password.required ? $t('login.password') : '' }} &nbsp;
          {{ !$v.form.password.minLength && $v.form.password.required ? $t('login.minLength', [8]) : '' }}
        </small>

        <b-button type="submit" :variant="$v.$invalid ? 'outline-primary' : 'primary'">
          {{ $t('login.signIn') }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import SpinnerLoader from '../components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
export default {
  name: 'Login',
  components: { SpinnerLoader },
  data() {
    return {
      isOpenPassword: true,
      form: {
        login: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      loginStatus: 'getLoginStatus',
      saleshouses: 'getSalehousesList',
    }),
  },
  // watch: {
  //     isLocale() {
  //         document.title = this.$i18n.t('sideBar.auth') + ' – OpenMediaLogic';
  //     }
  // },
  created() {
    document.title = this.$i18n.t('sideBar.auth') + ' – OpenMediaLogic';
  },
  validations: {
    form: {
      login: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(254),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.war'),
          text: this.$i18n.t('alert.loginError'),
          duration: 3000,
        });
      } else {
        const formData = {
          login: this.form.login,
          password: this.form.password,
        };
        await this.$store.dispatch('POST_LOGIN', {
          formData,
          handler: async () => {
            const token = this.$cookies.get('token');
            if (token) {
              await this.$store.dispatch('POST_ME', {
                //get permissions
                handler: () => {
                  let searchParams = new URLSearchParams(window.location.search); // restore query before unauth attempt
                  if (searchParams.has('redirect')) {
                    this.$router.push({ path: `${searchParams.get('redirect')}` });
                  } else this.$router.push({ path: '/profile' });
                },
                handlerError: (errors) => {
                  errorsHandler(errors, this.$notify);
                },
              });
              await this.$store.dispatch('GET_SALEHOUSES');
              // set selected saleshouse from local storage
              const selectedSaleshouse = JSON.parse(localStorage.getItem('selectedSaleshouse'));
              if (selectedSaleshouse) {
                this.$store.dispatch('SET_CHANGE_SALESHOUSE', this.saleshouses?.some(s => s.id === selectedSaleshouse.id) ? selectedSaleshouse : null);
              } else {
                this.$store.dispatch('SET_CHANGE_SALESHOUSE', this.saleshouses?.length == 1 ? this.saleshouses[0] : null);
              }
            }
          },
          handlerError: (err) => {
            if (err?.status === 401) {
              this.$notify({
                type: 'error',
                title: this.$i18n.t('alert.war'),
                text: this.$i18n.t('alert.loginError'),
                duration: 3000,
              });
            } else {
              errorsHandler(err, this.$notify);
            }
          },
        });
      }
    },
  },
};
</script>

<style lang="sass">
@use '~/src/assets/sass/_variables' as *

.wrapper-login
  width: 100vw
  height: calc(100vh - 76px)
  display: flex
  justify-content: center
  align-items: center

.login-form
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.1)
  border-radius: .25rem
  background-color: #fff
  padding: 40px 50px
  width: 800px
  color: #4b4b5a!important

  .helper
    display: block
    visibility: hidden

  .show-error-helper
    visibility: visible

  .password-eye
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 16px

  // For Safari (for no overlap with keychain btn)
  @supports not (overflow-x: clip)
    .password-eye
      right: 3rem

[data-color-scheme="dark"]
  .login-form
    background-color: $bg-dark
    box-shadow: 0 0.05rem 0.01rem rgba(161, 168, 184, 0.1)
    color: hsl(222, 14%, 70%) !important
</style>
