export default function (errors, notify) {
  //if (process.env.NODE_ENV === 'development') {
  if (!(errors && errors.status)) {
    notify({
      type: 'error',
      title: errors || 'Error',
      duration: 4000,
    });
  } else if (errors.status === 400) {
    if (errors.data.warnings) {
      notify({
        type: 'error',
        title: errors.data.warnings[0],
        duration: 5000,
      });
    }
  } else if (errors.status === 422) {
    if (errors.data.errors) {
      for (let val in errors.data.errors) {
        //show all n validation errors
        notify({
          type: 'error',
          title: 'Error',
          text: errors.data.errors[val][0],
          duration: 5000,
        });
      }
    } else if (errors.data) {
      notify({
        type: 'error',
        title: 'Error',
        text: errors.data[Object.keys(errors.data)[0]][0],
        duration: 5000,
      });
    }
    // if (errors.data.from) {
    //   notify({
    //     type: "error",
    //     title: errors.data.from[0],
    //     duration: 7000,
    //   });
    // }
  } else if (errors.status === 500) {
    if (errors.data.from) {
      notify({
        type: 'error',
        title: 'Error 500',
        text: errors.data.from[0],
        duration: 5000,
      });
    } else if (errors.data) {
      notify({
        type: 'error',
        title: 'Error 500',
        text: errors.data.message,
        duration: 5000,
      });
    }
  } else if (errors.status === 404) {
    if (errors.data.from) {
      notify({
        type: 'error',
        title: errors.data.from[0],
        duration: 5000,
      });
    } else if (errors.data.message) {
      notify({
        type: 'error',
        title: errors.data.message[0],
        duration: 5000,
      });
    }
  } else if (errors.status === 401) {
    if (errors.data) {
      notify({
        type: 'error',
        title: errors.data[Object.keys(errors.data)[0]][0],
        duration: 5000,
      });
    }
  } else if (errors.status) {
    if (errors.data && errors.data.message) {
      notify({
        type: 'error',
        title: 'Error ' + errors.status,
        text: errors.data.message,
        duration: 5000,
      });
    }
  }
  //}
}
