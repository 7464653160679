import Vue from 'vue';
import { i18n } from '@/i18n';
import { getAdvertisers, postAdvertisers, getAdvertisersId, putAdvertisersId, deleteAdvertisersId } from '../../api/apiServices';

export default {
  state: {
    modalEditAdvertisers: '',
    advertisers: '',
    advertiser: '',
    advertisersStatus: '',
    advertisersList: '',
    advertisersListWithAll: '',
  },
  mutations: {
    getModalEditAdvertisers(state, payload) {
      Vue.set(state, 'modalEditAdvertisers', payload.data);
    },
    getAdvertiserRequest: (state) => {
      state.advertisersStatus = 'loading';
    },
    getAdvertiserSuccess: (state, res) => {
      state.advertisersStatus = 'success';
      Vue.set(state, 'advertisers', res);
    },
    getAdvertiser2Success: (state, res) => {
      Vue.set(state, 'advertisersList', res);
      Vue.set(state, 'advertisersListWithAll', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getAdvertiser: (state, res) => {
      Vue.set(state, 'advertiser', res);
    },
    getAdvertiserError: (state) => {
      state.advertisersStatus = 'error';
    },

    clearAdvertisersList: (state) => {
      state.modalEditAdvertisers = '';
      state.advertisersStatus = '';
      state.advertiser = '';
      Vue.set(state, 'advertisers', '');
      Vue.set(state, 'advertisersList', '');
      Vue.set(state, 'advertisersListWithAll', '');
    },
  },
  actions: {
    async GET_ADVERTISERS({ dispatch, commit }, payload) {
      commit('getAdvertiserRequest');
      await getAdvertisers(payload)
        .then((res) => {
          commit('getAdvertiserSuccess', res.data);
          commit('getAdvertiser2Success', res.data.data);
        })
        .catch((e) => {
          commit('getAdvertiserError');
        });
    },
    async GET_ADVERTISER({ dispatch, commit }, payload) {
      await getAdvertisers(payload)
        .then((res) => {
          commit('getAdvertiser', res.data);
        })
        .catch((e) => {});
    },
    async POST_ADVERTISERS({ dispatch, commit }, payload) {
      await postAdvertisers(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_ADVERTISERS_ID({ dispatch, commit }, payload) {
      await getAdvertisersId(payload)
        .then((res) => {
          commit('getModalEditAdvertisers', res.data);
        })
        .catch((e) => {});
    },
    async PUT_ADVERTISERS_ID({ dispatch, commit }, payload) {
      const { id, name, agencies } = payload.formData;

      await putAdvertisersId(id, {
        name,
        agencies,
      })
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_ADVERTISERS_ID({ dispatch, commit }, payload) {
      await deleteAdvertisersId(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditAdvertisers: (state) => state.modalEditAdvertisers,
    getAdvertisers: (state) => state.advertisers,
    getAdvertiser: (state) => state.advertiser,
    getAdvertisersList: (state) => state.advertisersList || [],
    getAdvertisersListWithAll: (state) => state.advertisersListWithAll || [],
    getAdvertisersStatus: (state) => state.advertisersStatus,
  },
};
