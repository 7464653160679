<template>
  <div>
    <LayoutHeader />
    <Login />
  </div>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader';
import Login from '@/views/Login';
import { mapGetters } from 'vuex';
export default {
  name: 'LoginLayout',
  components: { Login, LayoutHeader },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
  },
};
</script>

<style scoped>
</style>
