import Vue from 'vue';
import { i18n } from '@/i18n';
import {
  getAgencies,
  postAgencies,
  getAgenciesId,
  putAgenciesId,
  deleteAgenciesId,
  putRestoreAgenciesId,
  putAllowMassCopyAgencies,
} from '../../api/apiServices';

export default {
  state: {
    modalEditAgencies: '',
    agencies: '',
    agenciesStatus: '',
    agenciesList: '',
    agenciesListWithAll: '',
  },
  mutations: {
    getModalEditAgencies(state, payload) {
      Vue.set(state, 'modalEditAgencies', payload.data);
    },
    getAgenciesListSuccess: (state, res) => {
      Vue.set(state, 'agenciesList', res);
      Vue.set(state, 'agenciesListWithAll', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getAgenciesRequest: (state) => {
      state.agenciesStatus = 'loading';
    },
    getAgenciesSuccess: (state, res) => {
      state.agenciesStatus = 'success';
      Vue.set(state, 'agencies', res);
    },
    getAgenciesError: (state) => {
      state.agenciesStatus = 'error';
    },

    clearAgenciesList: (state) => {
      state.modalEditAgencies = '';
      state.agenciesStatus = '';
      Vue.set(state, 'agencies', '');
      Vue.set(state, 'agenciesList', '');
      Vue.set(state, 'agenciesListWithAll', '');
    },
  },
  actions: {
    async GET_AGENCIES({ dispatch, commit }, payload) {
      commit('getAgenciesRequest');
      await getAgencies(payload)
        .then((res) => {
          commit('getAgenciesSuccess', res.data);
          commit('getAgenciesListSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getAgenciesError');
        });
    },
    async POST_AGENCIES({ dispatch, commit }, payload) {
      const { name, advertisers } = payload.formData;

      await postAgencies({
        name,
        advertisers,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_AGENCIES_ID({ dispatch, commit }, payload) {
      await getAgenciesId(payload)
        .then((res) => {
          commit('getModalEditAgencies', res.data);
        })
        .catch((e) => {});
    },
    async PUT_AGENCIES({ dispatch, commit }, payload) {
      const { id, name, advertisers } = payload.formData;
      await putAgenciesId(id, {
        name,
        advertisers,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_AGENCIES_ID({ dispatch, commit }, payload) {
      await deleteAgenciesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_RESTORE_AGENCIES({ dispatch, commit }, payload) {
      await putRestoreAgenciesId(payload.id)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async PUT_ALLOW_MASS_COPY_AGENCIES({ dispatch, commit }, payload) {
      await putAllowMassCopyAgencies(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {
    getModalEditAgencies: (state) => state.modalEditAgencies,
    getAgencies: (state) => state.agencies,
    getAgenciesList: (state) => state.agenciesList || [],
    getAgenciesListWithAll: (state) => state.agenciesListWithAll || [],
    getAgenciesStatus: (state) => state.agenciesStatus,
  },
};
