import Vue from 'vue';
import { getYear } from '../../api/apiServices';

export default {
  state: {
    year: [],
    yearStatus: '',
  },
  mutations: {
    getYearRequest: (state) => {
      state.yearStatus = 'loading';
    },
    getYearSuccess: (state, res) => {
      state.yearStatus = 'success';
      Vue.set(state, 'year', res);
    },
    getYearError: (state) => {
      state.yearStatus = 'error';
    },
    clearYearsList: (state) => {
      state.year = [];
      state.yearStatus = '';
    },
  },
  actions: {
    async GET_YEAR({ dispatch, commit }, payload) {
      commit('getYearRequest');
      await getYear()
        .then((res) => {
          commit('getYearSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getYearError');
        });
    },
  },
  getters: {
    getYear: (state) => state.year,
    getYearStatus: (state) => state.yearStatus,
  },
};
