import Vue from 'vue';
import { i18n } from '@/i18n';
import { getSubchannels, postSubchannels, getSubchannelsId, putSubchannelsId, deleteSubchannelsId } from '../../api/apiServices';

export default {
  state: {
    modalEditSubchannels: '',
    subchannels: '',
    subchannel: '',
    subchannelsStatus: '',
    subchannelsList: '',
    subchannelsListWithAll: '',
  },
  mutations: {
    getModalEditSubchannels(state, payload) {
      Vue.set(state, 'modalEditSubchannels', payload.data);
    },
    getSubchannelRequest: (state) => {
      state.subchannelsStatus = 'loading';
    },
    getSubchannelSuccess: (state, res) => {
      state.subchannelsStatus = 'success';
      Vue.set(state, 'subchannels', res);
    },
    getSubchannel2Success: (state, res) => {
      Vue.set(state, 'subchannelsList', res);
      Vue.set(state, 'subchannelsListWithAll', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getSubchannel: (state, res) => {
      Vue.set(state, 'subchannel', res);
    },
    getSubchannelError: (state) => {
      state.subchannelsStatus = 'error';
    },

    clearSubchannelsList: (state) => {
      state.modalEditSubchannels = '';
      state.subchannelsStatus = '';
      state.subchannel = '';
      Vue.set(state, 'subchannels', '');
      Vue.set(state, 'subchannelsList', '');
      Vue.set(state, 'subchannelsListWithAll', '');
    },
  },
  actions: {
    async GET_SUBCHANNELS({ dispatch, commit }, payload) {
      commit('getSubchannelRequest');
      await getSubchannels(payload)
        .then((res) => {
          commit('getSubchannelSuccess', res.data);
          commit('getSubchannel2Success', res.data.data);
        })
        .catch((e) => {
          commit('getSubchannelError');
        });
    },
    async GET_ADVERTISER({ dispatch, commit }, payload) {
      await getSubchannels(payload)
        .then((res) => {
          commit('getSubchannel', res.data);
        })
        .catch((e) => {});
    },
    async POST_SUBCHANNELS({ dispatch, commit }, payload) {
      await postSubchannels(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_SUBCHANNELS_ID({ dispatch, commit }, payload) {
      await getSubchannelsId(payload)
        .then((res) => {
          commit('getModalEditSubchannels', res.data);
        })
        .catch((e) => {});
    },
    async PUT_SUBCHANNELS_ID({ dispatch, commit }, payload) {
      const { id, name, agencies } = payload.formData;

      await putSubchannelsId(id, {
        name,
        agencies,
      })
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_SUBCHANNELS_ID({ dispatch, commit }, payload) {
      await deleteSubchannelsId(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditSubchannels: (state) => state.modalEditSubchannels,
    getSubchannels: (state) => state.subchannels,
    getSubchannel: (state) => state.subchannel,
    getSubchannelsList: (state) => state.subchannelsList || [],
    getSubchannelsListWithAll: (state) => state.subchannelsListWithAll || [],
    getSubchannelsStatus: (state) => state.subchannelsStatus,
  },
};
