import Vue from 'vue';
import {
  getCoPlacementProhibitions,
  postCoPlacementProhibitions,
  getCoPlacementProhibitionsId,
  putCoPlacementProhibitionsId,
  deleteCoPlacementProhibitionsId,
} from '../../api/apiServices';

export default {
  state: {
    modalEditCoPlacementProhibitions: '',
    coPlacementProhibitions: '',
    coPlacementProhibitionsStatus: '',
  },
  mutations: {
    getModalEditCoPlacementProhibitions(state, payload) {
      Vue.set(state, 'modalEditCoPlacementProhibitions', payload.data);
    },
    getCoPlacementProhibitionsRequest: (state) => {
      state.coPlacementProhibitionsStatus = 'loading';
    },
    getCoPlacementProhibitionsSuccess: (state, res) => {
      state.coPlacementProhibitionsStatus = 'success';
      Vue.set(state, 'coPlacementProhibitions', res);
    },
    getCoPlacementProhibitionsError: (state) => {
      state.coPlacementProhibitionsStatus = 'error';
    },
    clearModalEditCoPlacementProhibitions: (state) => {
      state.modalEditCoPlacementProhibitions = '';
    },
    clearCoProhibitionsList: (state) => {
      state.coPlacementProhibitionsStatus = '';
      state.modalEditCoPlacementProhibitions = '';
      Vue.set(state, 'coPlacementProhibitions', '');
    },
  },
  actions: {
    async GET_CO_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      commit('getCoPlacementProhibitionsRequest');
      await getCoPlacementProhibitions(payload)
        .then((res) => {
          commit('getCoPlacementProhibitionsSuccess', res.data);
        })
        .catch((e) => {
          commit('getCoPlacementProhibitionsError');
        });
    },
    async POST_CO_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      await postCoPlacementProhibitions(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_CO_PLACEMENT_PROHIBITIONS_ID({ dispatch, commit }, payload) {
      await getCoPlacementProhibitionsId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditCoPlacementProhibitions', res.data);
        })
        .catch((e) => {});
    },
    async PUT_CO_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      const id = payload.id;
      await putCoPlacementProhibitionsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_CO_PLACEMENT_PROHIBITIONS_ID({ dispatch, commit }, payload) {
      await deleteCoPlacementProhibitionsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditCoPlacementProhibitions: (state) => state.modalEditCoPlacementProhibitions,
    getCoPlacementProhibitions: (state) => state.coPlacementProhibitions,
    getCoPlacementProhibitionsStatus: (state) => state.coPlacementProhibitionsStatus,
  },
};
