import Vue from 'vue';
import { getPriceProjects, postPriceProjects, getPriceProjectsId, putPriceProjectsId, deletePriceProjectsId } from '../../api/apiServices';

export default {
  state: {
    modalEditPriceProjects: '',
    priceProjects: '',
    priceProjectsStatus: '',
  },
  mutations: {
    getModalEditPriceProjects(state, payload) {
      Vue.set(state, 'modalEditPriceProjects', payload.data);
    },
    getPriceProjectsRequest: (state) => {
      state.priceProjectsStatus = 'loading';
    },
    getPriceProjectsSuccess: (state, res) => {
      state.priceProjectsStatus = 'success';
      Vue.set(state, 'priceProjects', res);
    },
    getPriceProjectsError: (state) => {
      state.priceProjectsStatus = 'error';
    },
    clearPriceProjects: (state) => {
      state.priceProjectsStatus = '';
      Vue.set(state, 'priceProjects', '');
      Vue.set(state, 'modalEditPriceProjects', '');
    },
    clearModalEditPriceProjects(state) {
      Vue.set(state, 'modalEditPriceProjects', '');
    },
  },
  actions: {
    async GET_PRICE_PROJECTS({ dispatch, commit }, payload) {
      commit('getPriceProjectsRequest');
      await getPriceProjects(payload)
        .then((res) => {
          commit('getPriceProjectsSuccess', res.data);
        })
        .catch((e) => {
          commit('getPriceProjectsError');
        });
    },
    // GRP
    async POST_PRICE_PROJECTS({ dispatch, commit }, payload) {
      await postPriceProjects(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PRICE_PROJECTS_ID({ dispatch, commit }, payload) {
      await getPriceProjectsId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditPriceProjects', res.data);
        })
        .catch((e) => {});
    },
    async PUT_PRICE_PROJECTS({ dispatch, commit }, payload) {
      const id = payload.priceProjectId;
      await putPriceProjectsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PRICE_PROJECTS_ID({ dispatch, commit }, payload) {
      await deletePriceProjectsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditPriceProjects: (state) => state.modalEditPriceProjects,
    getPriceProjects: (state) => state.priceProjects,
    getPriceProjectsStatus: (state) => state.priceProjectsStatus,
  },
};
