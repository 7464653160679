import Vue from 'vue';
import {
  postGrpUpload,
  getBlockGrps,
  putBlockGrps,
  postCopyGrpDay,
  postFactGrpUpload,
  putUpdateSpotFactGrps,
  getBindFactGrps,
  getFactGrps,
  putUnbindSpotFactGrps,
  unbindAllSpotsInDay,
  getCheckImportsFact,
  getExportFactImportSpots,
  postBindFactByExternalId,
  postFactGrpBind,
  getExportFactGrps,
} from '../../api/apiServices';

export default {
  state: {
    blockGRP: '',
    bindFactGrps: '',
    factGrps: '',
    bindFactGrpsStatus: '',
    bindFactGrpsStatusCode: '',
  },
  mutations: {
    setBlockGRP(state, payload) {
      Vue.set(state, 'blockGRP', payload);
    },
    setBindFactGRP(state, payload) {
      state.bindFactGrpsStatus = 'success';
      state.bindFactGrpsStatusCode = '';
      Vue.set(state, 'bindFactGrps', payload);
    },
    setFactGRP(state, payload) {
      Vue.set(state, 'factGrps', payload);
    },
    setBindFactGRPRequest: (state) => {
      state.bindFactGrpsStatus = 'loading';
      state.bindFactGrpsStatusCode = '';
    },
    setBindFactGRPError: (state, code) => {
      state.bindFactGrpsStatus = 'error';
      state.bindFactGrpsStatusCode = code;
    },
    clearBindFactGrps: (state) => {
      state.bindFactGrpsStatus = '';
      state.bindFactGrpsStatusCode = '';
      state.bindFactGrps = '';
    },
  },
  actions: {
    // PLAN GRPS
    async POST_GRP_UPLOAD({ dispatch, commit }, payload) {
      await postGrpUpload(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async PUT_BLOCK_GRP({ dispatch, commit }, payload) {
      await putBlockGrps(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async DELETE_UNBIND_ALL_IN_DAY({ dispatch, commit }, payload) {
      await unbindAllSpotsInDay(payload.broadcastDay, payload.channelId)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async GET_BLOCK_GRP_ID({ dispatch, commit }, payload) {
      await getBlockGrps(payload)
        .then((res) => commit('setBlockGRP', res.data))
        .catch((e) => {});
    },
    async POST_COPY_DAY_GRP({ dispatch, commit }, payload) {
      await postCopyGrpDay(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },

    // FACT GRPS
    async POST_FACT_GRP_UPLOAD({ dispatch, commit }, payload) {
      await postFactGrpUpload(payload.formData)
        .then((res) => payload.handler(res.data))
        .catch((err) => payload.handlerError(err));
    },
    async PUT_UPDATE_SPOT_FACT_GRP({ dispatch, commit }, payload) {
      await putUpdateSpotFactGrps(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async GET_BIND_FACT_GRP_ID({ dispatch, commit }, payload) {
      // commit('setBindFactGRPRequest');
      await getBindFactGrps(payload)
        .then((res) => commit('setBindFactGRP', res.data.data))
        .catch((e) => commit('setBindFactGRPError', e?.status));
    },
    async GET_CHECK_IMPORTS_FACT({ dispatch, commit }, payload) {
      await getCheckImportsFact(payload.formData)
        .then((res) => payload.handler(res.data))
        .catch((e) => {});
    },
    async GET_FACT_GRP_ID({ dispatch, commit }, payload) {
      await getFactGrps(payload.grpId, payload.params)
        .then((res) => commit('factGrps', res.data))
        .catch((e) => {});
    },
    async PUT_UNBIND_SPOT_FACT_GRP({ dispatch, commit }, payload) {
      await putUnbindSpotFactGrps(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },

    async GET_EXPORT_IMPORT_FACT_SPOTS({ dispatch, commit }, payload) {
      await getExportFactImportSpots(payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    async GET_EXPORT_FACT_GRPS({ dispatch, commit }, payload) {
      await getExportFactGrps(payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },

    async POST_BIND_FACT_BY_MATCHING_ID({ dispatch, commit }, payload) {
      await postBindFactByExternalId(payload.formData)
        .then(() => payload.handler())
        .catch((err) => payload.handlerError(err));
    },

    async POST_BIND_FACT({ dispatch, commit }, payload) {
      await postFactGrpBind(payload.formData)
        .then(() => payload.handler())
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {
    getBlockGrp: (state) => state.blockGRP || [],
    getBindFactGrps: (state) => state.bindFactGrps || [],
    getBindFactGrpsStatus: (state) => state.bindFactGrpsStatus,
    getBindFactGrpsStatusCode: (state) => state.bindFactGrpsStatusCode,
  },
};
