//Check permissions function for router
export default {
  checkEnv(...envs) {
    let res = true;
    envs.forEach((env, i) => {
      if (process.env['VUE_APP_' + env] != 'TRUE') {
        res = false;
      }
    });
    return res;
  },
};
