<template>
  <header class="inner-header">
    <b-button v-b-toggle.sidebar-header variant="outline-secondary" class="mr-2 border-0 btn-header router-link" :disabled="!Object.keys(userData).length">
      <b-icon icon="list" />
    </b-button>
    <b-sidebar
      id="sidebar-header"
      lazy
      :title="$t('sideBar.menu')"
      :bg-variant="isThemeHeader === 'true' ? 'dark' : 'light'"
      :class="isThemeHeader === 'true' ? 'white-color' : ''"
      shadow
      backdrop
      header-class="px-4"
    >
      <div class="px-4 py-2">
        <router-link
          v-for="(link, index) in sideBarLinks"
          :key="index"
          :to="link.path"
          class="d-block py-1 my-3 router-link"
          active-class="current danger-color"
        >
          {{ link.title }}
        </router-link>
      </div>
    </b-sidebar>
    <router-link class="logo logo-li" :to="{ name: 'profile' }">
      <span class="danger-color">Open</span>
      <span :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">MediaLogic</span>
    </router-link>
    <small class="ml-2 info text-muted">v3.10.1</small>
    <small v-if="isDev" class="danger-color ml-1 mr-1 font-weight-bold info">(DEV)</small>
    <div v-if="Object.keys(userData).length" class="d-flex align-items-center flex-fill px-3 justify-content-center">
      <MultiSelect
        v-if="saleshouses?.length > 1"
        :value="selectedSaleshouse"
        :options="saleshousesList"
        :multiple="false"
        :placeholder="$t('header.allSaleshouses')"
        :allow-empty="true"
        class="multiselect-sm max-w-300-px"
        name="id"
        track-by="id"
        label="name"
        @input="updateSelectedSaleshouse"
      ></MultiSelect>
      <span v-else class="text-center text-muted flex-fill">{{ selectedSaleshouse?.name }}</span>
    </div>
    <div class="header__right d-flex align-items-center ml-auto">
      <b-button
        id="popover-user-settings"
        size="sm"
        variant="outline-secondary"
        class="border-0 btn-header"
        :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
        @keydown.stop.esc="closeSettingsPopover"
      >
        <span v-if="Object.keys(userData).length">
          <span class="text-muted mr-3 d-none d-lg-inline-block">{{ userData.roles && userData.roles.length > 0 ? userData.roles[0].name : '' }}</span>
          <span class="d-none d-md-inline-block">{{ userData.email }}</span>
          &nbsp;
        </span>
        <BIconGear />
      </b-button>
      <BPopover target="popover-user-settings" :show.sync="showSettingsPopover" triggers="click" placement="bottomright">
        <template #title>
          <div class="d-flex w-full justify-content-between align-items-center" @keydown.stop.esc="closeSettingsPopover">
            <p class="mb-0 h5">{{ $t('table.settings') }}</p>

            <button
              type="button"
              class="border-0 btn-transparent btn-header router-link p-1"
              :aria-label="$t('table.close')"
              :title="$t('table.close')"
              style="font-size: 20px; line-height: 1"
              @click="closeSettingsPopover"
            >
              <BIconX />
            </button>
          </div>
        </template>

        <form v-click-outside="closeSettingsPopover" tabindex="0" @keydown.stop.esc="closeSettingsPopover" @submit.prevent>
          <!-- interface settings -->
          <div class="py-3">
            <b-form-checkbox
              size="sm"
              variant="outline-secondary"
              switch
              class="mb-1"
              :title="$t('header.toggleDarkTheme')"
              :checked="isThemeHeader === 'true'"
              @change="changeTheme"
            >
              <p class="mb-0">{{ $t('header.toggleDarkTheme') }}</p>
            </b-form-checkbox>
            <span class="mb-0">{{ $t('header.interfaceLanguage') }}</span>
            <b-form-select v-model="selectedLanguage" :options="languageOptions" size="sm" class="ml-3 w-auto" />
          </div>
          <!-- id visibility settings -->
          <div v-if="isAdmin" class="py-3">
            <p class="h6 py-2">{{ $t('table.IdVisibility') }} ({{ $t('table.forCurrentBrowserSession') }})</p>
            <b-form-checkbox :checked="showSpotID" class="mb-1" switch @change="$store.commit('toggleShowSpotID')">
              <p class="mb-0">{{ $t('table.showSpotId') }}</p>
              <small class="text-muted">
                ({{ $t('sideBar.broadcast') }}, {{ $t('table.booking') }}, {{ $t('table.versionControl') }}, {{ $t('broadcastSideBar.oneDay') }})
              </small>
            </b-form-checkbox>
            <b-form-checkbox :checked="showProgramReleaseID" class="mb-1" switch @change="$store.commit('toggleShowProgramReleaseID')">
              <p class="mb-0">{{ $t('table.showProgramReleaseId') }}</p>
              <small class="text-muted">({{ $t('sideBar.broadcast') }}, {{ $t('table.versionControl') }}, {{ $t('broadcastSideBar.oneDay') }})</small>
            </b-form-checkbox>
            <b-form-checkbox :checked="showSummaryMpID" class="mb-1" switch @change="$store.commit('toggleShowSummaryMpID')">
              <p class="mb-0">{{ $t('table.showSummaryMpId') }}</p>
              <small class="text-muted">({{ $t('table.mediaPlansSummary') }})</small>
            </b-form-checkbox>
          </div>
          <!-- logout button -->
          <div v-if="Object.keys(userData).length" class="user-login mr-3 py-2 text-center" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
            <button class="btn-transparent router-link color-inherit ml-1 text-center" :title="$t('login.logout')" type="button" @click="logout">
              <b-icon icon="box-arrow-right" />
              <span class="d-none d-md-inline-block">&nbsp;{{ $t('login.logout') }}</span>
            </button>
          </div>
        </form>
      </BPopover>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';
import { BSidebar, VBToggle, BIconBrightnessHigh, BIconMoon, BIconGear, BPopover, BIconX } from 'bootstrap-vue';
import MultiSelect from '@/components/MultiSelect';
import { loadLanguageAsync } from '@/i18n';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'LayoutHeader',
  components: {
    BSidebar,
    BPopover,
    BIconGear,
    BIconX,
    MultiSelect,
  },
  directives: { 'b-toggle': VBToggle, ClickOutside },
  data() {
    return {
      userData: {},
      selectedLanguage: this.$root.$i18n.locale,
      showSettingsPopover: false,
      languageOptions: [
        { value: null, text: ' ', disabled: true },
        { value: 'en', text: 'English' },
        { value: 'ru', text: 'Русский' },
        { value: 'vi', text: 'Tiếng Việt' },
      ],
      saleshousesList: [],
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      user: 'getUser',
      broadcastChannel: 'getBroadcastChannel',
      isDev: 'getDevFlag',
      isAdmin: 'getIsCurrentUserAdmin',
      showSpotID: 'getShowSpotID',
      showProgramReleaseID: 'getShowProgramReleaseID',
      showSummaryMpID: 'getShowSummaryMpID',
      saleshouses: 'getSalehousesList',
      selectedSaleshouse: 'getSelectedSaleshouse',
    }),
    sideBarLinks() {
      const linksArr = [];
      if (this.$checkPermissions('scope.administration_dashboard')) linksArr.push({ path: '/admin', title: this.$t('table.admin') });
      if (this.$checkPermissions('scope.broadcasting_grid')) linksArr.push({ path: '/broadcast', title: this.$t('sideBar.broadcast') });
      if (this.$checkPermissions('broadcasting.channel_one_day')) linksArr.push({ path: '/broadcast-one-day', title: this.$t('table.versionControl') });
      if (this.$checkPermissions('scope.marketing_grid')) linksArr.push({ path: '/marketing-grid', title: this.$t('table.marketing') });
      if (this.$checkPermissions('grp_fact.bind', 'grp_fact.unbind')) linksArr.push({ path: '/matching-fact-import', title: this.$t('table.matchingImport') });
      if (this.$checkPermissions('scope.project_order_mediaplan')) linksArr.push({ path: '/projects', title: this.$t('sideBar.projects') });
      if (this.$checkPermissions('scope.booking_grid')) linksArr.push({ path: '/booking', title: this.$t('table.booking') });
      if (this.$checkPermissions('booking.channel_one_day')) linksArr.push({ path: '/booking-one-day', title: this.$t('broadcastSideBar.oneDay') });
      if (this.$checkPermissions('scope.mediaplan_automatic_list'))
        linksArr.push({ path: '/automatic-booking', title: this.$t('broadcastSideBar.automatControl') });
      if (this.$checkPermissions('scope.commercial_list')) linksArr.push({ path: '/commercials-list', title: this.$t('sideBar.commercials') });
      if (this.$checkPermissions('scope.reports')) linksArr.push({ path: '/reports', title: this.$t('broadcastSideBar.reports') });
      if (process.env.VUE_APP_ENABLE_RETROBONUS == 'TRUE' && this.$checkPermissions('retro_bonus_discount.manage'))
        linksArr.push({ path: '/retro-bonus-discounts', title: this.$t('RBDiscounts.retroBonusDiscounts') });
      return linksArr;
    },
  },
  watch: {
    user() {
      this.setCurrentUserData();
    },
    async selectedLanguage(locale) {
      try {
        this.broadcastChannel?.postMessage({ type: 'update_locale', locale: locale });
      } catch (e) {
        console.log('Error while sending BC message');
      }
      await loadLanguageAsync(locale);
      this.$root.$i18n.locale = locale;
    },
    '$root.$i18n.locale'() {
      this.selectedLanguage = this.$root.$i18n.locale; //update select in header after BC message
    },
    saleshouses() {
      this.saleshousesList = [{ id: null, name: this.$i18n.t('table.all') }, ...this.saleshouses];
    },
  },
  mounted() {
    this.setCurrentUserData();
    this.saleshousesList = [{ id: null, name: this.$i18n.t('table.all') }, ...this.saleshouses];
  },
  created() {},
  methods: {
    async logout() {
      this.userData = {};
      await this.$store.dispatch('POST_LOGOUT', {
        handler: () => this.$router.push({ name: 'login' }),
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },
    setCurrentUserData() {
      const token = this.$cookies.get('token');
      if (this.user && token) this.userData = this.user;
      else this.userData = {};
    },

    changeTheme() {
      this.$store.dispatch('SET_CHANGE_THEME', this.isThemeHeader === 'false' ? 'true' : 'false');
      try {
        this.broadcastChannel?.postMessage({ type: 'update_theme' });
      } catch (e) {
        console.log('Error while sending BC message');
      }
    },

    closeSettingsPopover() {
      this.showSettingsPopover = false;
    },

    async updateSelectedSaleshouse(saleshouse) {
      await this.$store.dispatch('SET_CHANGE_SALESHOUSE', saleshouse);
      window.location.reload();
    },
  },
};
</script>

<style lang="sass">
#sidebar-header
  a.router-link
    color: inherit

.max-w-300-px
  max-width: 300px
</style>
