import Vue from 'vue';
import { getPlacementProhibitions, postPlacementProhibitions, getPlacementProhibitionsId, putPlacementProhibitionsId, deletePlacementProhibitionsId } from '../../api/apiServices';

export default {
  state: {
    modalEditPlacementProhibitions: '',
    placementProhibitions: '',
    placementProhibitionsStatus: '',
  },
  mutations: {
    getModalEditPlacementProhibitions(state, payload) {
      Vue.set(state, 'modalEditPlacementProhibitions', payload.data);
    },
    getPlacementProhibitionsRequest: (state) => {
      state.placementProhibitionsStatus = 'loading';
    },
    getPlacementProhibitionsSuccess: (state, res) => {
      state.placementProhibitionsStatus = 'success';
      Vue.set(state, 'placementProhibitions', res);
    },
    getPlacementProhibitionsError: (state) => {
      state.placementProhibitionsStatus = 'error';
    },
    clearModalEditPlacementProhibitions: (state) => {
      state.modalEditPlacementProhibitions = '';
    },
    clearPlacementProhibitionsList: (state) => {
      state.placementProhibitionsStatus = '';
      state.modalEditPlacementProhibitions = '';
      Vue.set(state, 'placementProhibitions', '');
    },
  },
  actions: {
    async GET_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      commit('getPlacementProhibitionsRequest');
      await getPlacementProhibitions(payload)
        .then((res) => {
          commit('getPlacementProhibitionsSuccess', res.data);
        })
        .catch((e) => {
          commit('getPlacementProhibitionsError');
        });
    },
    async POST_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      await postPlacementProhibitions(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PLACEMENT_PROHIBITIONS_ID({ dispatch, commit }, payload) {
      await getPlacementProhibitionsId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditPlacementProhibitions', res.data);
        })
        .catch((e) => {});
    },
    async PUT_PLACEMENT_PROHIBITIONS({ dispatch, commit }, payload) {
      const id = payload.id;
      await putPlacementProhibitionsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PLACEMENT_PROHIBITIONS_ID({ dispatch, commit }, payload) {
      await deletePlacementProhibitionsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditPlacementProhibitions: (state) => state.modalEditPlacementProhibitions,
    getPlacementProhibitions: (state) => state.placementProhibitions,
    getPlacementProhibitionsStatus: (state) => state.placementProhibitionsStatus,
  },
};
