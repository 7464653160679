import Vue from 'vue';
import {
  getBroadcastId,
  postBroadcastCopyWeek,
  postBroadcastCopyDay,
  postBroadcastDeleteDay,
  postBroadcastDeleteWeek,
  patchCloseDays,
  patchOpenDays,
  patchBroadcastingDisallowEjection,
  patchBroadcastingAllowEjection,
  getExportBroadcast,
  getOneDayBroadcast,
  getBroadcastingSettings,
  postPreparationDay,
  postBroadcastCopyProgramRelease,
  getBroadcastClosedMonths,
  postBroadcastOpenCloseMonth,
} from '../../api/apiServices';

export default {
  state: {
    broadcast: '',
    broadcastStatus: '',
    broadcastOneDay: '',
    broadcastOneDayStatus: '',
    broadcastingSettings: [],
    broadcastClosedMonths: [],
  },
  mutations: {
    getBroadcastRequest: (state) => {
      state.broadcastStatus = 'loading';
    },
    getBroadcastSuccess: (state, res) => {
      state.broadcastStatus = 'success';
      Vue.set(state, 'broadcast', res);
    },
    getBroadcastError: (state) => {
      state.broadcastStatus = 'error';
    },
    getBroadcastOneDayRequest: (state) => {
      state.broadcastOneDayStatus = 'loading';
    },
    getBroadcastOneDaySuccess: (state, res) => {
      state.broadcastOneDayStatus = 'success';
      Vue.set(state, 'broadcastOneDay', res);
    },
    getBroadcastOneDayError: (state) => {
      state.broadcastOneDayStatus = 'error';
    },
    setBroadcastingSettings: (state, res) => {
      Vue.set(state, 'broadcastingSettings', res);
    },
    clearBroadcastingSettings: (state) => {
      Vue.set(state, 'broadcastingSettings', []);
    },
    removeFromSettings: (state, arr) => {
      const filtered = state.broadcastingSettings.filter((el) => !arr.includes(el));
      Vue.set(state, 'broadcastingSettings', filtered);
    },
    clearBroadcast: (state) => {
      state.broadcastStatus = '';
      Vue.set(state, 'broadcast', '');
    },
    clearBroadcastOneDay: (state) => {
      state.broadcastOneDayStatus = '';
      Vue.set(state, 'broadcastOneDay', '');
    },
    setBroadcastClosedMonths: (state, res) => {
      Vue.set(state, 'broadcastClosedMonths', res);
    },
    clearBroadcastClosedMonths: (state) => {
      Vue.set(state, 'broadcastClosedMonths', []);
    },
  },
  actions: {
    async GET_BROADCAST({ dispatch, commit }, payload) {
      //commit('getBroadcastRequest');
      await getBroadcastId(payload.data, payload.date)
        .then((res) => {
          commit('getBroadcastSuccess', res.data);
        })
        .catch((e) => {
          commit('getBroadcastError');
        });
    },
    async GET_BROADCASTING_SETTINGS({ dispatch, commit }, payload) {
      commit('clearBroadcastingSettings');
      await getBroadcastingSettings(payload.channelId, payload.date)
        .then((res) => {
          commit('setBroadcastingSettings', res.data.data);
        })
        .catch((e) => {});
    },
    async POST_BROADCAST_COPY_DAY({ dispatch, commit }, payload) {
      const { date_from, date_to, force } = payload.date;
      const { channel } = payload.data;
      await postBroadcastCopyDay({ channel }, { date_from, date_to, force })
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async POST_BROADCAST_COPY_PROGRAM_RELEASE({ dispatch, commit }, payload) {
      await postBroadcastCopyProgramRelease(payload.channel_id, payload.date, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async POST_BROADCAST_COPY_WEEK({ dispatch, commit }, payload) {
      const { date_from_start_at, date_from_end_at, date_to_start_at, date_to_end_at, force } = payload.date;
      const { channel } = payload.data;
      await postBroadcastCopyWeek({ channel }, { date_from_start_at, date_from_end_at, date_to_start_at, date_to_end_at, force })
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async POST_BROADCAST_DELETE_DAY({ dispatch, commit }, payload) {
      const { date } = payload.date;
      const { channel } = payload.data;
      await postBroadcastDeleteDay({ channel }, { date })
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async POST_BROADCAST_DELETE_WEEK({ dispatch, commit }, payload) {
      const { date_from, date_to } = payload.date;
      const { channel } = payload.data;
      await postBroadcastDeleteWeek({ channel }, { date_from, date_to })
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async PATCH_CLOSE_DAYS({ dispatch, commit }, payload) {
      await patchCloseDays(payload.channel_id, payload.days)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async PATCH_OPEN_DAYS({ dispatch, commit }, payload) {
      await patchOpenDays(payload.channel_id, payload.days)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    async PATCH_BROADCASTING_DISALLOW_EJECTION({ dispatch, commit }, payload) {
      await patchBroadcastingDisallowEjection(payload.channel_id, payload.days)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async PATCH_BROADCASTING_ALLOW_EJECTION({ dispatch, commit }, payload) {
      await patchBroadcastingAllowEjection(payload.channel_id, payload.days)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    async POST_PREPARE_DAY({ dispatch, commit }, payload) {
      await postPreparationDay(payload.channelId, payload.formData)
        .then((res) => {
          payload.handler(res.data);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    async GET_EXPORT_BROADCAST({ dispatch, commit }, payload) {
      await getExportBroadcast(payload.id, payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async GET_BROADCAST_ONE_DAY({ dispatch, commit }, payload) {
      //commit('getBroadcastOneDayRequest');
      await getOneDayBroadcast(payload.data, payload.date)
        .then((res) => {
          commit('getBroadcastOneDaySuccess', res.data);
        })
        .catch((e) => {
          commit('getBroadcastOneDayError');
        });
    },

    async GET_BROADCAST_CLOSED_MONTHS({ dispatch, commit }, payload) {
      commit('clearBroadcastClosedMonths');
      await getBroadcastClosedMonths(payload.data)
        .then((res) => commit('setBroadcastClosedMonths', res.data.data))
        .catch((e) => {});
    },
    async POST_OPEN_CLOSE_MONTH({ dispatch, commit }, payload) {
      await postBroadcastOpenCloseMonth(payload.formData)
        .then((res) => payload.handler(res))
        .catch((e) => payload.handlerError(e));
    },
  },
  getters: {
    getBroadcast: (state) => state.broadcast,
    getBroadcastStatus: (state) => state.broadcastStatus,
    getBroadcastOneDay: (state) => state.broadcastOneDay,
    getBroadcastOneDayStatus: (state) => state.broadcastOneDayStatus,
    getBroadcastingSettings: (state) => state.broadcastingSettings,
    getBroadcastClosedMonths: (state) => state.broadcastClosedMonths,
  },
};
