import { postReplaceSpots, postDeleteSpots, postFixSpots, postSwapSpot, putIncreaseBid } from '../../api/apiServices';

export default {
  state: {},
  mutations: {},
  actions: {
    async POST_REPLACE_SPOTS({ dispatch, commit }, payload) {
      await postReplaceSpots(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async POST_DELETE_SPOTS({ dispatch, commit }, payload) {
      await postDeleteSpots(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async POST_FIX_SPOTS({ dispatch, commit }, payload) {
      await postFixSpots(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async POST_SWAP_SPOT({ dispatch, commit }, payload) {
      await postSwapSpot(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async PUT_INCREASE_BID({ dispatch, commit }, payload) {
      await putIncreaseBid(payload.blockId, payload.spotId, payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {},
};
