import Vue from 'vue';
import { getPrograms, postPrograms, getProgramsId, putProgramsId, deleteProgramsId } from '../../api/apiServices';

export default {
  state: {
    modalEditPrograms: '',
    programs: '',
    programsStatus: '',
  },
  mutations: {
    getModalEditPrograms(state, payload) {
      Vue.set(state, 'modalEditPrograms', payload.data);
    },
    getProgramsRequest: (state) => {
      state.programsStatus = 'loading';
    },
    getProgramsSuccess: (state, res) => {
      state.programsStatus = 'success';
      Vue.set(state, 'programs', res);
    },
    getProgramsError: (state) => {
      state.programsStatus = 'error';
    },
    clearPrograms: (state) => {
      state.programsStatus = '';
      state.modalEditPrograms = '';
      Vue.set(state, 'programs', '');
    },
  },
  actions: {
    async GET_PROGRAMS({ dispatch, commit }, payload) {
      commit('getProgramsRequest');
      await getPrograms(payload)
        .then((res) => {
          commit('getProgramsSuccess', res.data);
        })
        .catch((e) => {
          commit('getProgramsError');
        });
    },
    async POST_PROGRAMS({ dispatch, commit }, payload) {
      const { name, genre_id, duration } = payload.formData;

      await postPrograms({
        name,
        genre_id,
        duration,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PROGRAMS_ID({ dispatch, commit }, payload) {
      await getProgramsId(payload)
        .then((res) => {
          commit('getModalEditPrograms', res.data);
        })
        .catch((e) => {});
    },
    async PUT_PROGRAMS({ dispatch, commit }, payload) {
      const { id, name, genre_id, duration } = payload.formData;
      await putProgramsId(id, {
        name,
        genre_id,
        duration,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PROGRAMS_ID({ dispatch, commit }, payload) {
      await deleteProgramsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditPrograms: (state) => state.modalEditPrograms,
    getPrograms: (state) => state.programs,
    getProgramsStatus: (state) => state.programsStatus,
  },
};
