import Vue from 'vue';
import {
  getPremiumPositionDiscounts,
  postPremiumPositionDiscounts,
  getPremiumPositionDiscountsId,
  putPremiumPositionDiscountsId,
  deletePremiumPositionDiscountsId,
} from '../../api/apiServices';

export default {
  state: {
    modalEditPremiumPositionDiscounts: '',
    premiumPositionDiscounts: '',
    premiumPositionDiscountsStatus: '',
  },
  mutations: {
    getModalEditPremiumPositionDiscounts(state, payload) {
      Vue.set(state, 'modalEditPremiumPositionDiscounts', payload.data);
    },
    getPremiumPositionDiscountsRequest: (state) => {
      state.premiumPositionDiscountsStatus = 'loading';
    },
    getPremiumPositionDiscountsSuccess: (state, res) => {
      state.premiumPositionDiscountsStatus = 'success';
      Vue.set(state, 'premiumPositionDiscounts', res);
    },
    getPremiumPositionDiscountsError: (state) => {
      state.premiumPositionDiscountsStatus = 'error';
    },
    clearPremPosDiscountsList: (state) => {
      state.premiumPositionDiscountsStatus = 'success';
      state.modalEditPremiumPositionDiscounts = '';
      Vue.set(state, 'premiumPositionDiscounts', '');
    },
  },
  actions: {
    async GET_PREMIUM_POSITION_DISCOUNTS({ dispatch, commit }, payload) {
      commit('getPremiumPositionDiscountsRequest');
      await getPremiumPositionDiscounts(payload)
        .then((res) => {
          commit('getPremiumPositionDiscountsSuccess', res.data);
        })
        .catch((e) => {
          commit('getPremiumPositionDiscountsError');
        });
    },
    async POST_PREMIUM_POSITION_DISCOUNTS({ dispatch, commit }, payload) {
      await postPremiumPositionDiscounts(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PREMIUM_POSITION_DISCOUNTS_ID({ dispatch, commit }, payload) {
      await getPremiumPositionDiscountsId(payload)
        .then((res) => {
          commit('getModalEditPremiumPositionDiscounts', res.data);
        })
        .catch((e) => {});
    },
    async PUT_PREMIUM_POSITION_DISCOUNTS({ dispatch, commit }, payload) {
      const id = payload.premiumPositionDiscountsId;
      await putPremiumPositionDiscountsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PREMIUM_POSITION_DISCOUNTS_ID({ dispatch, commit }, payload) {
      await deletePremiumPositionDiscountsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditPremiumPositionDiscounts: (state) => state.modalEditPremiumPositionDiscounts,
    getPremiumPositionDiscounts: (state) => state.premiumPositionDiscounts,
    getPremiumPositionDiscountsStatus: (state) => state.premiumPositionDiscountsStatus,
  },
};
