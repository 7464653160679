import Vue from 'vue';
import { getTarget, postTarget, getTargetId, putTargetId, deleteTargetId } from '../../api/apiServices';

export default {
  state: {
    modalEditTarget: '',
    target: null,
    targetStatus: '',
  },
  mutations: {
    getModalEditTarget(state, payload) {
      Vue.set(state, 'modalEditTarget', payload.data);
    },
    getTargetRequest: (state) => {
      state.targetStatus = 'loading';
    },
    getTargetSuccess: (state, res) => {
      state.targetStatus = 'success';
      Vue.set(state, 'target', res);
    },
    getTargetError: (state) => {
      state.targetStatus = 'error';
    },
    clearTargetsList: (state) => {
      state.targetStatus = '';
      state.modalEditTarget = '';
      Vue.set(state, 'target', '');
    },
  },
  actions: {
    async GET_TARGET({ dispatch, commit }, payload) {
      commit('getTargetRequest');
      await getTarget(payload)
        .then((res) => {
          commit('getTargetSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getTargetError');
        });
    },
    async POST_TARGET({ dispatch, commit }, payload) {
      await postTarget(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_TARGET_ID({ dispatch, commit }, payload) {
      await getTargetId(payload)
        .then((res) => {
          commit('getModalEditTarget', res.data);
        })
        .catch((e) => {});
    },
    async PUT_TARGET_ID({ dispatch, commit }, payload) {
      const { id, name, measurement_company_id } = payload.formData;
      await putTargetId(id, {
        name,
        measurement_company_id,
      })
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_TARGET_ID({ dispatch, commit }, payload) {
      await deleteTargetId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getTarget: (state) => state.target,
    getTargetList: (state) => state.target || [],
    getTargetStatus: (state) => state.targetStatus,
    getModalEditTarget: (state) => state.modalEditTarget,
  },
};
