import Vue from 'vue';
import { getBookingId, getOneDayBooking, postCopyBooking, getExportBooking, getExportBookingOneDay, deleteClearQueue } from '../../api/apiServices';

export default {
  state: {
    booking: '',
    bookingStatus: '',
    bookingOneDay: '',
    bookingOneDayStatus: '',
  },
  mutations: {
    getBookingRequest: (state) => {
      state.bookingStatus = 'loading';
    },
    getBookingSuccess: (state, res) => {
      state.bookingStatus = 'success';
      Vue.set(state, 'booking', res);
    },
    getBookingError: (state) => {
      state.bookingStatus = 'error';
    },
    getBookingOneDayRequest: (state) => {
      state.bookingOneDayStatus = 'loading';
    },
    getBookingOneDaySuccess: (state, res) => {
      state.bookingOneDayStatus = 'success';
      Vue.set(state, 'bookingOneDay', res);
    },
    getBookingOneDayError: (state) => {
      state.bookingStatus = 'error';
    },
    clearBooking: (state) => {
      state.bookingStatus = '';
      Vue.set(state, 'booking', '');
    },
    clearBookingOneDay: (state) => {
      state.bookingOneDayStatus = '';
      Vue.set(state, 'bookingOneDay', '');
    },
  },
  actions: {
    async GET_BOOKING({ dispatch, commit }, payload) {
      //commit('getBookingRequest');
      const { channel } = payload.data;
      await getBookingId({ channel }, payload.date)
        .then((res) => {
          commit('getBookingSuccess', res.data);
        })
        .catch((e) => {
          commit('getBookingError');
        });
    },
    async GET_BOOKING_ONE_DAY({ dispatch, commit }, payload) {
      //commit('getBookingOneDayRequest');
      await getOneDayBooking(payload.data, payload.date)
        .then((res) => {
          commit('getBookingOneDaySuccess', res.data);
        })
        .catch((e) => {
          commit('getBookingOneDayError');
        });
    },

    async DELETE_CLEAR_QUEUE({ dispatch, commit }, payload) {
      await deleteClearQueue(payload.id, payload.date)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    async POST_COPY_BOOKING({ dispatch, commit }, payload) {
      await postCopyBooking(payload.id, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    async GET_EXPORT_BOOKING({ dispatch, commit }, payload) {
      await getExportBooking(payload.id, payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async GET_EXPORT_BOOKING_ONE_DAY({ dispatch, commit }, payload) {
      await getExportBookingOneDay(payload.id, payload.type, payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
  },
  getters: {
    getBooking: (state) => state.booking,
    getBookingStatus: (state) => state.bookingStatus,
    getBookingOneDay: (state) => state.bookingOneDay,
    getBookingOneDayStatus: (state) => state.bookingOneDayStatus,
  },
};
