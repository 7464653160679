import Vue from 'vue';
import { getRetroBonusDiscounts, postRetroBonusApprove } from '../../api/apiServices';

export default {
  state: {
    RBDiscounts: null,
    RBDiscountsStatus: '',
  },
  mutations: {
    getRBDiscountsRequest: (state) => {
      state.RBDiscountsStatus = 'loading';
    },
    getRBDiscountsSuccess: (state, res) => {
      state.RBDiscountsStatus = 'success';
      Vue.set(state, 'RBDiscounts', res);
    },
    getRBDiscountsError: (state) => {
      state.RBDiscountsStatus = 'error';
    },

    clearRBDiscounts: (state) => {
      state.RBDiscountsStatus = '';
      Vue.set(state, 'RBDiscounts', '');
    },
  },
  actions: {
    async GET_RETRO_BONUS_DISCOUNTS({ dispatch, commit }, payload) {
      commit('getRBDiscountsRequest');
      await getRetroBonusDiscounts(payload)
        .then((res) => {
          commit('getRBDiscountsSuccess', res.data);
        })
        .catch((e) => {
          commit('getRBDiscountsError');
        });
    },
    async POST_RETRO_BONUS_DISCOUNTS_APPROVE({ dispatch, commit }, payload) {
      await postRetroBonusApprove(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {
    getRBDiscounts: (state) => state.RBDiscounts,
    getRBDiscountsStatus: (state) => state.RBDiscountsStatus,
  },
};
