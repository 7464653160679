import Vue from 'vue';
import { i18n } from '@/i18n';
import { getCommercialType } from '../../api/apiServices';

export default {
  state: {
    commercialType: [],
    commercialTypeWithoutAll: [],
    commercialTypeStatus: '',
  },
  mutations: {
    getCommercialTypeRequest: (state) => {
      state.commercialTypeStatus = 'loading';
    },
    getCommercialTypeSuccess: (state, res) => {
      state.commercialTypeStatus = 'success';
      Vue.set(state, 'commercialType', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getCommercialTypeSuccessWithoutAll: (state, res) => {
      state.commercialTypeStatus = 'success';
      Vue.set(state, 'commercialTypeWithoutAll', res);
    },
    getCommercialTypeError: (state) => {
      state.commercialTypeStatus = 'error';
    },
    clearCommercialType: (state) => {
      state.commercialType = [];
      state.commercialTypeWithoutAll = [];
      state.commercialTypeStatus = '';
    },
  },
  actions: {
    async GET_COMMERCIAL_TYPE({ dispatch, commit }, payload) {
      commit('getCommercialTypeRequest');
      await getCommercialType()
        .then((res) => {
          commit('getCommercialTypeSuccess', res.data.data);
          commit('getCommercialTypeSuccessWithoutAll', res.data.data);
        })
        .catch((e) => {
          commit('getCommercialTypeError');
        });
    },
  },
  getters: {
    getCommercialType: (state) => state.commercialType || [],
    getCommercialTypeWithoutAll: (state) => state.commercialTypeWithoutAll || [],
    getCommercialTypeStatus: (state) => state.commercialTypeStatus,
  },
};
