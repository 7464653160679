import Vue from 'vue';
import { getRoles, getRolesId } from '../../api/apiServices';

export default {
  state: {
    getRoleId: '',
    roles: '',
    rolesStatus: '',
  },
  mutations: {
    setRoleId(state, res) {
      Vue.set(state, 'getRoleId', res);
    },
    getRolesRequest: (state) => {
      state.rolesStatus = 'loading';
    },
    getRolesSuccess: (state, res) => {
      state.rolesStatus = 'success';
      Vue.set(state, 'roles', res);
    },
    getRolesError: (state) => {
      state.rolesStatus = 'error';
    },
    clearRoles: (state) => {
      state.roles = '';
      state.rolesStatus = '';
    },
  },
  actions: {
    async GET_ROLES({ dispatch, commit }, payload) {
      commit('getRolesRequest');
      await getRoles(payload)
        .then((res) => {
          commit('getRolesSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getRolesError');
        });
    },
    async GET_ROLES_ID({ dispatch, commit }, payload) {
      await getRolesId(payload.id, payload.data)
        .then((res) => {
          commit('setRoleId', res.data.data);
        })
        .catch((e) => {});
    },
  },
  getters: {
    getRoleId: (state) => state.getRoleId,
    getRoles: (state) => state.roles || [],
    getRolesStatus: (state) => state.rolesStatus,
  },
};
