import { postRetroBonusScaleUpload, getRetroBonusScale } from '../../api/apiServices';
import Vue from 'vue';

export default {
  state: {
    RBScale: null,
    RBScaleStatus: '',
  },
  mutations: {
    getRBScaleRequest: (state) => {
      state.RBScaleStatus = 'loading';
    },
    getRBScaleSuccess: (state, res) => {
      state.RBScaleStatus = 'success';
      Vue.set(state, 'RBScale', res);
    },
    getRBScaleError: (state) => {
      state.RBScaleStatus = 'error';
    },

    clearRBScale: (state) => {
      state.RBScaleStatus = '';
      Vue.set(state, 'RBScale', '');
    },
  },
  actions: {
    async POST_RETRO_BONUS_SCALE_UPLOAD({ dispatch, commit }, payload) {
      await postRetroBonusScaleUpload(payload.formData)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
    async GET_RETRO_BONUS_SCALE({ dispatch, commit }, payload) {
      commit('getRBScaleRequest');
      await getRetroBonusScale(payload)
        .then((res) => {
          commit('getRBScaleSuccess', res.data);
        })
        .catch((e) => {
          commit('getRBScaleError');
        });
    },
  },
  getters: {
    getRBScale: (state) => state.RBScale,
    getRBScaleStatus: (state) => state.RBScaleStatus,
  },
};
