import Vue from 'vue';
import { getCommercials, postCommercials, getCommercialsId, putCommercialsId, deleteCommercialsId, putCommercialsRestoreId } from '../../api/apiServices';

export default {
  state: {
    modalEditCommercials: '',
    commercials: '',
    commercialsStatus: '',
  },
  mutations: {
    getModalEditCommercials(state, payload) {
      Vue.set(state, 'modalEditCommercials', payload.data);
    },
    getCommercialsRequest: (state) => {
      state.commercialsStatus = 'loading';
    },
    getCommercialsSuccess: (state, res) => {
      state.commercialsStatus = 'success';
      Vue.set(state, 'commercials', res);
    },
    getCommercialsError: (state) => {
      state.commercialsStatus = 'error';
    },
    clearModalEditCommercials: (state) => {
      state.modalEditCommercials = '';
    },
    clearCommercialsList: (state) => {
      state.commercialsStatus = '';
      Vue.set(state, 'commercials', '');
      state.modalEditCommercials = '';
    },
  },
  actions: {
    async GET_COMMERCIALS({ dispatch, commit }, payload) {
      commit('getCommercialsRequest');
      await getCommercials(payload)
        .then((res) => {
          commit('getCommercialsSuccess', res.data);
        })
        .catch((e) => {
          commit('getCommercialsError');
        });
    },
    async GET_COMMERCIALS_WO_STATUS({ dispatch, commit }, payload) {
      await getCommercials(payload)
        .then((res) => {
          commit('getCommercialsSuccess', res.data);
        })
        .catch((e) => {
          commit('getCommercialsError');
        });
    },
    async POST_COMMERCIALS({ dispatch, commit }, payload) {
      await postCommercials(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_COMMERCIALS_ID({ dispatch, commit }, payload) {
      await getCommercialsId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditCommercials', res.data);
        })
        .catch((e) => {});
    },
    async PUT_COMMERCIALS({ dispatch, commit }, payload) {
      const id = payload.commercialId;
      await putCommercialsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_COMMERCIALS_ID({ dispatch, commit }, payload) {
      await deleteCommercialsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_COMMERCIALS_RESTORE_ID({ dispatch, commit }, payload) {
      await putCommercialsRestoreId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditCommercials: (state) => state.modalEditCommercials,
    getCommercialsList: (state) => state.commercials,
    getCommercialsStatus: (state) => state.commercialsStatus,
  },
};
