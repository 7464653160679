<template>
  <div>
    <LayoutHeader />
    <div class="main-content-wrapper">
      <SideBar />
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/AdminLayoutSideBar';
import LayoutHeader from '@/components/LayoutHeader';
import { mapGetters } from 'vuex';
export default {
  name: 'AdminLayout',
  components: { LayoutHeader, SideBar },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
  },
  created() {
    document.title = 'OpenMediaLogic';
  },
};
</script>

<style lang="sass" src="../assets/sass/adminPanel.sass">
</style>
