import Vue from 'vue';
import { getChannelcompany, postChannelcompany, getChannelcompanyId, putChannelcompanyId, deleteChannelcompanyId } from '../../api/apiServices';

export default {
  state: {
    channelsCompany: [],
    channelsCompanyStatus: '',
  },
  mutations: {
    getChannelcompanyRequest: (state) => {
      state.channelsCompanyStatus = 'loading';
    },
    getChannelcompanySuccess: (state, res) => {
      state.channelsCompanyStatus = 'success';
      Vue.set(state, 'channelsCompany', res);
    },
    getChannelcompanyError: (state) => {
      state.channelsCompanyStatus = 'error';
    },
    clearChannelCompanies: (state, res) => {
      state.channelsCompanyStatus = '';
      Vue.set(state, 'channelsCompany', []);
    },
  },
  actions: {
    async GET_CHANNELS_COMPANY({ dispatch, commit }, payload) {
      commit('getChannelcompanyRequest');
      await getChannelcompany(payload)
        .then((res) => {
          commit('getChannelcompanySuccess', res.data.data);
        })
        .catch((e) => {
          commit('getChannelcompanyError');
        });
    },
    async POST_CHANNELS_COMPANY({ dispatch, commit }, payload) {
      const { name } = payload.formData;

      await postChannelcompany({
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_CHANNELS_COMPANY_ID({ dispatch, commit }, payload) {
      await getChannelcompanyId(payload)
        .then((res) => {
          commit('getModalEditSalehouses', res.data);
        })
        .catch((e) => {});
    },
    async PUT_CHANNELS_COMPANY({ dispatch, commit }, payload) {
      const { id, name } = payload.formData;
      await putChannelcompanyId(id, {
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_CHANNELS_COMPANY_ID({ dispatch, commit }, payload) {
      await deleteChannelcompanyId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getChannelsCompany: (state) => state.channelsCompany,
    getChannelsCompanyStatus: (state) => state.channelsCompanyStatus,
  },
};
