import Vue from 'vue';
import { getUsers, postUsers, getUsersId, putUsersId, deleteUsersId, putUsersRestore } from '../../api/apiServices';

export default {
  state: {
    users: null,
    usersStatus: '',
  },
  mutations: {
    getUsersRequest: (state) => {
      state.usersStatus = 'loading';
    },
    getUsersSuccess: (state, res) => {
      state.usersStatus = 'success';
      Vue.set(state, 'users', res);
    },
    getUsersError: (state) => {
      state.usersStatus = 'error';
    },
    clearUsersList: (state) => {
      state.usersStatus = 'success';
      Vue.set(state, 'users', '');
    },
  },
  actions: {
    async GET_USERS({ dispatch, commit }, payload) {
      commit('getUsersRequest');
      await getUsers(payload)
        .then((res) => {
          commit('getUsersSuccess', res.data);
        })
        .catch((e) => {
          commit('getUsersError');
        });
    },
    async POST_USERS({ dispatch, commit }, payload) {
      await postUsers(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_USERS_ID({ dispatch, commit }, payload) {
      await getUsersId(payload.include, payload.id)
        .then((res) => {
          payload.handler(res.data.data);
        })
        .catch((e) => {
          if (payload.handlerError) payload.handlerError(e);
        });
    },
    async PUT_USERS({ dispatch, commit }, payload) {
      await putUsersId(payload.id, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_USERS_ID({ dispatch, commit }, payload) {
      await deleteUsersId(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_USERS_RESTORE({ dispatch, commit }, payload) {
      await putUsersRestore(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getUsers: (state) => state.users,
    getUsersList: (state) => (state.users && state.users.data) || [],
    getUsersStatus: (state) => state.usersStatus,
  },
};
