//Discounts in Projects
//import Vue from 'vue';
import { deleteDiscountsId, putDiscountsRestoreId } from '../../api/apiServices';

export default {
  state: {},
  mutations: {},
  actions: {
    async DELETE_DISCOUNTS_ID({ dispatch, commit }, payload) {
      await deleteDiscountsId(payload.formData)
        .then((res) => payload.handler())
        .catch((err) => payload.handlerError(err));
    },
    async PUT_DISCOUNTS_RESTORE_ID({ dispatch, commit }, payload) {
      await putDiscountsRestoreId(payload.formData)
        .then((res) => payload.handler())
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {},
};
