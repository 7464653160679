<template>
  <div id="app" class="bgc">
    <template v-if="loading === 'success'">
      <template v-if="layout">
        <component :is="layout">
          <router-view />
        </component>
      </template>
      <template v-else>
        <router-view />
      </template>
      <!-- EXTEND SESSION MODAL -->
      <b-modal
        id="extend-current-session"
        size="sm"
        :title="$t('table.sessionIsExpiring', [lessThanMinutes])"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        @hidden="isOpenedExtendModal = false"
        @ok="extendSession"
      >
        <p class="my-2">
          {{ $t('table.extendSession') }}
        </p>
      </b-modal>
    </template>
    <div v-else class="overflow-hidden">
      <SpinnerLoader :loading="loading" />
    </div>
    <notifications classes="new-notifications" position="bottom left" :max="3" :duration="350"></notifications>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutHeader from './components/LayoutHeader';
import AdminLayout from '@/layouts/AdminLayout';
import LoginLayout from '@/layouts/LoginLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '@/components/SpinnerLoader';
import update from '@/mixins/update';
import { loadLanguageAsync } from './i18n';

export default {
  name: 'App',
  components: { LoginLayout, AdminLayout, LayoutHeader, DefaultLayout, SpinnerLoader },
  mixins: [update],
  data() {
    return {
      isOpenedExtendModal: false,
      lessThanMinutes: 11,
      loading: 'loading',
      checkForTokenModalInterval: null,
    };
  },

  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + '-layout';
      } else {
        return undefined;
      }
    },
    ...mapGetters({
      user: 'getUser',
      isThemeHeader: 'isTheme',
      broadcastChannel: 'getBroadcastChannel',
      showSpotID: 'getShowSpotID',
      showProgramReleaseID: 'getShowProgramReleaseID',
      showSummaryMpID: 'getShowSummaryMpID',
      saleshouses: 'getSalehousesList',
    }),
  },

  watch: {
    '$root.$i18n.locale'(locale) {
      localStorage.setItem('locale', locale);
      this.$store.dispatch('SET_CHANGE_LOCALE', locale);
    },
    selectedSaleshouse(saleshouse) {
      localStorage.setItem('selectedSaleshouse', saleshouse);
      this.$store.dispatch('SET_CHANGE_SALESHOUSE', saleshouse);
    },
    isThemeHeader(newValue) {
      localStorage.setItem('theme', newValue);
      document.documentElement.setAttribute('data-color-scheme', this.isThemeHeader === 'true' ? 'dark' : 'light'); //set dark theme to get dark scrollbars, etc.
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', this.isThemeHeader === 'true' ? '#373c49' : '#ffffff');
    },
  },

  async created() {
    this.$store.commit('CREATE_BROADCAST_CHANNEL'); // to share theme and locale changes across all tabs
    //set theme from LS
    const theme = localStorage.getItem('theme');
    this.$store.dispatch('SET_CHANGE_THEME', theme);
    document.documentElement.setAttribute('data-color-scheme', theme === 'true' ? 'dark' : 'light');
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme === 'true' ? '#373c49' : '#ffffff');
    // set locale from LS
    const locale = localStorage.getItem('locale');
    const defaultLang = process.env.VUE_APP_DEFAULT_LANGUAGE ? process.env.VUE_APP_DEFAULT_LANGUAGE.toLowerCase() : 'en';
    if (locale) {
      await loadLanguageAsync(locale);
      this.$root.$i18n.locale = locale;
    } else {
      // If no lang in LS - set default lang from .env
      await loadLanguageAsync(defaultLang);
      this.$root.$i18n.locale = defaultLang;
    }
    // set selected saleshouse from local storage
    this.selectedSaleshouse = localStorage.getItem('selectedSaleshouse');
    //set event listener on Broadcast channel
    if (this.broadcastChannel)
      this.broadcastChannel.onmessage = async (messageEvent) => {
        const data = messageEvent.data;
        switch (data.type) {
          case 'update_locale':
            if (!data.locale) break;
            await loadLanguageAsync(data.locale);
            this.$root.$i18n.locale = data.locale;
            break;
          case 'update_theme':
            this.$store.dispatch('SET_CHANGE_THEME', this.isThemeHeader === 'false' ? 'true' : 'false');
            break;
          case 'close_extend_session_modal':
            if (this.isOpenedExtendModal) this.$bvModal.hide('extend-current-session');
            break;
          case 'update_isShowProgramReleaseID':
            if (data.isShowProgramReleaseID !== undefined && data.isShowProgramReleaseID !== this.showProgramReleaseID)
              this.$store.commit('toggleShowProgramReleaseID');
            break;
          case 'update_isShowSpotID':
            if (data.isShowSpotID !== undefined && data.isShowSpotID !== this.showSpotID) this.$store.commit('toggleShowSpotID');
            break;
          case 'update_isShowSummaryMpID':
            if (data.isShowSummaryMpID !== undefined && data.isShowSummaryMpID !== this.showSummaryMpID) this.$store.commit('toggleShowSummaryMpID');
            break;
          default:
            console.log('Received a message via BroadcastChannel API');
        }
      };

    //Restore auth
    const token = this.$cookies.get('token');
    if (token) {
      const expireTimeCookie = this.$cookies.get('tokenCookieExpireIn'); // get auth token expire time if exists
      let checkIfFailed = false;
      await this.$store.dispatch('POST_ME', {
        handlerError: (errors) => {
          checkIfFailed = true;
          errorsHandler(errors, this.$notify);
        },
      });
      await this.$store.dispatch('GET_SALEHOUSES');
      // set selected saleshouse from local storage
      const selectedSaleshouse = JSON.parse(localStorage.getItem('selectedSaleshouse'));
      if (selectedSaleshouse) {
        this.$store.dispatch('SET_CHANGE_SALESHOUSE', this.saleshouses?.some(s => s.id === selectedSaleshouse.id) ? selectedSaleshouse : null);
      } else {
        this.$store.dispatch('SET_CHANGE_SALESHOUSE', this.saleshouses?.length == 1 ? this.saleshouses[0] : selectedSaleshouse);
      }

      // update cookie expire date-time and set it to vuex if POST_ME success and token lifetime is <60min
      const timeDiff = expireTimeCookie ? +expireTimeCookie - Date.now() : undefined;
      if ((!expireTimeCookie || timeDiff < 1000 * 60 * 60) && !checkIfFailed) await this.$store.dispatch('REFRESH_TOKEN');
    }

    //interval checks if a token will die soon and open modal to refresh it
    this.checkForTokenModalInterval = setInterval(this.checkTokenTimeToShowModal, 1000 * 60 * 10); //last number in min

    this.loading = 'success';
  },

  destroyed() {
    clearInterval(this.checkForTokenModalInterval);
  },

  methods: {
    checkTokenTimeToShowModal() {
      //if less than 11min and modal isn't opened
      const tokenCookie = this.$cookies.get('token');
      const expireTimeCookie = this.$cookies.get('tokenCookieExpireIn');
      const timeDiff = expireTimeCookie && tokenCookie ? +expireTimeCookie - Date.now() : undefined;
      if (expireTimeCookie && tokenCookie && timeDiff < 1000 * 60 * this.lessThanMinutes && !this.isOpenedExtendModal) {
        this.isOpenedExtendModal = true;
        this.$bvModal.show('extend-current-session');
      }
    },

    async extendSession() {
      if (this.$cookies.get('token')) {
        await this.$store.dispatch('REFRESH_TOKEN').then(() => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.sessionExtended'),
          });
        });
      } else {
        this.$notify({
          type: 'warning',
          title: this.$i18n.t('table.tooLate'),
          text: this.$i18n.t('table.needToRelogin'),
          duration: 5000,
        });
        this.$store.commit('clearUser');
        this.$store.commit('clearChannelsList');
        this.$store.commit('clearBrandsList');
        this.$store.commit('clearSalesHousesList');
        this.$store.commit('clearSelectedSaleshouse');
        this.$store.commit('clearAdvertisersList');
        this.$store.commit('clearAgenciesList');
        this.$store.commit('clearProjects');
        localStorage.removeItem('currentUserPermissions');
        localStorage.removeItem('currentUserRole');
        const currentPath = this.$route.fullPath;
        this.$router.push({ path: '/', query: { redirect: currentPath } });
      }
      // close this modal in other tabs
      try {
        this.broadcastChannel?.postMessage({ type: 'close_extend_session_modal' });
      } catch (e) {
        console.log('Error while sending BC message');
      }
    },
  },
};
</script>

<style lang="scss">
// Import Bootstrap and BootstrapVue source SCSS files
//@import '~bootstrap/scss/bootstrap.scss';
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';
// 2. Include any default variable overrides here
$blue: #dc3545;
$secondary: hsl(221deg, 14%, 45%);
// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
$theme-colors: map-remove($theme-colors, 'info', 'warning');
// 4. Include any optional Bootstrap components as you like
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
//@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import '~bootstrap/scss/close';
//@import "~bootstrap/scss/toasts";
@import '~bootstrap/scss/modal';
//@import "~bootstrap/scss/tooltip";
@import '~bootstrap/scss/popover';
//@import "~bootstrap/scss/carousel";
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import '~bootstrap-vue/src/index.scss';
</style>

<style lang="sass">
@import 'assets/sass/darkTheme'
@import 'assets/sass/main'
</style>
