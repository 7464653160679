import Vue from 'vue';
import {
  getMediaPlans,
  postMediaPlans,
  getMediaPlansId,
  putMediaPlansId,
  deleteMediaPlansId,
  putMediaPlansRestoreId,
  getMediaPlanIdTable,
  getMediaPlansAutomatic,
  postMediaPlansAutomaticExecute,
  getMediaPlansExport,
  getMediaPlansExportList,
  getMediaPlanIdWGRP,
  calculateWgrpAndBudgets,
  calculateAllWgrpAndBudgets,
  getMediaPlansSummary,
  getMediaPlansSummaryExport,
} from '../../api/apiServices';

export default {
  state: {
    modalEditMediaPlans: '',
    mediaPlans: '',
    mediaPlansStatus: '',
    mediaPlanTable: '',
    mediaPlanTableStatus: null,
    automaticMediaPlans: '',
    automaticMediaPlansStatus: '',
    wgrpTable: '',
    mediaPlansSummary: '',
    mediaPlansSummaryStatus: '',
  },
  mutations: {
    getModalEditMediaPlans(state, payload) {
      Vue.set(state, 'modalEditMediaPlans', payload.data);
    },
    getMediaPlansRequest: (state) => {
      state.mediaPlansStatus = 'loading';
    },
    getMediaPlansSuccess: (state, res) => {
      state.mediaPlansStatus = 'success';
      Vue.set(state, 'mediaPlans', res);
    },
    getMediaPlansError: (state) => {
      state.mediaPlansStatus = 'error';
    },
    clearMediaPlans: (state) => {
      state.mediaPlans = '';
      state.wgrpTable = '';
      state.modalEditMediaPlans = '';
    },
    setMediaPlanTable: (state, payload) => {
      Vue.set(state, 'mediaPlanTable', payload);
    },
    setMediaPlanTableStatus: (state, status) => {
      Vue.set(state, 'mediaPlanTableStatus', status);
    },
    setAutomaticMediaPlansRequest: (state) => {
      state.automaticMediaPlansStatus = 'loading';
    },
    setAutomaticMediaPlansSuccess: (state, res) => {
      state.automaticMediaPlansStatus = 'success';
      Vue.set(state, 'automaticMediaPlans', res);
    },
    setAutomaticMediaPlansError: (state) => {
      state.automaticMediaPlansStatus = 'error';
    },
    setWGRPtable: (state, payload) => {
      Vue.set(state, 'wgrpTable', payload.data);
    },
    clearWGRPtable: (state) => {
      Vue.set(state, 'wgrpTable', '');
    },
    clearMPtable: (state) => {
      Vue.set(state, 'mediaPlanTable', '');
      Vue.set(state, 'mediaPlanTableStatus', null);
    },

    setMediaPlansSummaryRequest: (state) => {
      state.mediaPlansSummaryStatus = 'loading';
    },
    setMediaPlansSummarySuccess: (state, res) => {
      state.mediaPlansSummaryStatus = 'success';
      Vue.set(state, 'mediaPlansSummary', res);
    },
    setMediaPlansSummaryError: (state) => {
      state.mediaPlansSummaryStatus = 'error';
    },
    clearMediaPlansSummary: (state) => {
      Vue.set(state, 'mediaPlansSummary', '');
      Vue.set(state, 'mediaPlansSummaryStatus', '');
    },
    clearAutomaticMediaPlans: (state) => {
      Vue.set(state, 'automaticMediaPlans', '');
      Vue.set(state, 'automaticMediaPlansStatus', '');
    },
  },
  actions: {
    async GET_MEDIA_PLANS({ dispatch, commit }, payload) {
      commit('getMediaPlansRequest');
      await getMediaPlans(payload)
        .then((res) => {
          commit('getMediaPlansSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getMediaPlansError');
        });
    },
    async GET_MEDIA_PLANS_SUMMARY({ dispatch, commit }, payload) {
      commit('setMediaPlansSummaryRequest');
      await getMediaPlansSummary(payload)
        .then((res) => {
          commit('setMediaPlansSummarySuccess', res.data.data);
        })
        .catch((e) => {
          commit('setMediaPlansSummaryError');
        });
    },
    async POST_MEDIA_PLANS({ dispatch, commit }, payload) {
      await postMediaPlans(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_MEDIA_PLANS_ID({ dispatch, commit }, payload) {
      await getMediaPlansId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditMediaPlans', res.data);
        })
        .catch((e) => {});
    },
    async PUT_MEDIA_PLANS({ dispatch, commit }, payload) {
      const id = payload.mediaPlanId;
      await putMediaPlansId(id, payload.formData)
        .then((res) => {
          payload.handler(res?.data);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_MEDIA_PLANS_ID({ dispatch, commit }, payload) {
      await deleteMediaPlansId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_MEDIA_PLANS_RESTORE_ID({ dispatch, commit }, payload) {
      await putMediaPlansRestoreId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async GET_MEDIA_PLANS_ID_TABLE({ dispatch, commit }, payload) {
      commit('clearMPtable');
      commit('setMediaPlanTableStatus', 'loading');
      await getMediaPlanIdTable(payload.id, payload.data)
        .then((res) => {
          commit('setMediaPlanTable', res.data);
          commit('setMediaPlanTableStatus', 'success');
        })
        .catch((e) => commit('setMediaPlanTableStatus', 'error'));
    },
    async GET_MEDIA_PLANS_WGRP_TABLE({ dispatch, commit }, payload) {
      await getMediaPlanIdWGRP(payload.id, payload.data)
        .then((res) => {
          commit('setWGRPtable', res.data);
        })
        .catch((e) => {});
    },

    async POST_RECALCULATE_WGRP_BUDGETS({ dispatch, commit }, payload) {
      await calculateWgrpAndBudgets(payload.mediaplan_id)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async POST_RECALCULATE_ALL_WGRP_BUDGETS({ dispatch, commit }, payload) {
      await calculateAllWgrpAndBudgets()
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async GET_AUTOMATIC_MEDIA_PLANS({ dispatch, commit }, payload) {
      commit('setAutomaticMediaPlansRequest');
      await getMediaPlansAutomatic(payload)
        .then((res) => {
          commit('setAutomaticMediaPlansSuccess', res.data);
        })
        .catch((e) => {
          commit('setAutomaticMediaPlansError');
        });
    },

    async POST_MEDIA_PLANS_AUTOMATON_EXECUTE({ dispatch, commit }, payload) {
      await postMediaPlansAutomaticExecute(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async GET_MEDIA_PLANS_EXPORT({ dispatch, commit }, payload) {
      await getMediaPlansExport(payload.id, payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async GET_MEDIA_PLANS_EXPORT_LIST({ dispatch, commit }, payload) {
      await getMediaPlansExportList(payload.id, payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
    async GET_MEDIA_PLANS_SUMMARY_EXPORT({ dispatch, commit }, payload) {
      await getMediaPlansSummaryExport(payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
  },
  getters: {
    getModalEditMediaPlans: (state) => state.modalEditMediaPlans,
    getMediaPlansList: (state) => state.mediaPlans || [],
    getMediaPlansStatus: (state) => state.mediaPlansStatus,
    getMediaPlanTable: (state) => state.mediaPlanTable || {},
    getMediaPlanTableStatus: (state) => state.mediaPlanTableStatus,
    getAutomaticMediaPlans: (state) => state.automaticMediaPlans,
    getAutomaticMediaPlansStatus: (state) => state.automaticMediaPlansStatus,
    getMediaPlanWGRPTable: (state) => state.wgrpTable,
    getMediaPlansSummary: (state) => state.mediaPlansSummary,
    getMediaPlansSummaryStatus: (state) => state.mediaPlansSummaryStatus,
  },
};
