import Vue from 'vue';
import { getSalehouses, postSalehouses, getSalehousesId, putSalehousesId, deleteSalehousesId } from '../../api/apiServices';

export default {
  state: {
    modalEditSalehouses: '',
    salehouses: null,
    salehousesStatus: '',
  },
  mutations: {
    getModalEditSalehouses(state, payload) {
      Vue.set(state, 'modalEditSalehouses', payload.data);
    },
    getSalehousesRequest: (state) => {
      state.salehousesStatus = 'loading';
    },
    getSalehousesSuccess: (state, res) => {
      state.salehousesStatus = 'success';
      Vue.set(state, 'salehouses', res);
    },
    getSalehousesError: (state) => {
      state.salehousesStatus = 'error';
    },
    clearSalesHousesList: (state) => {
      state.salehousesStatus = '';
      state.modalEditSalehouses = '';
      Vue.set(state, 'salehouses', '');
    },
  },
  actions: {
    async GET_SALEHOUSES({ dispatch, commit }, payload) {
      commit('getSalehousesRequest');
      await getSalehouses(payload)
        .then((res) => {
          commit('getSalehousesSuccess', res.data);
        })
        .catch((e) => {
          commit('getSalehousesError');
        });
    },
    async POST_SALEHOUSES({ dispatch, commit }, payload) {
      const { name } = payload.formData;

      await postSalehouses({
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_SALEHOUSES_ID({ dispatch, commit }, payload) {
      await getSalehousesId(payload)
        .then((res) => {
          commit('getModalEditSalehouses', res.data);
        })
        .catch((e) => {});
    },
    async PUT_SALEHOUSES({ dispatch, commit }, payload) {
      const { id, name } = payload.formData;
      await putSalehousesId(id, {
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_SALEHOUSES_ID({ dispatch, commit }, payload) {
      await deleteSalehousesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditSalehouses: (state) => state.modalEditSalehouses,
    getSalehouses: (state) => state.salehouses,
    getSalehousesList: (state) => (state.salehouses && state.salehouses.data) || [],
    getSalehousesStatus: (state) => state.salehousesStatus,
  },
};
