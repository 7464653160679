import Vue from 'vue';
import { i18n } from '@/i18n';
import { getBlockTypes } from '../../api/apiServices';

export default {
  state: {
    blockTypes: [],
    blockTypesWithoutAll: [],
    blockTypesStatus: '',
  },
  mutations: {
    getBlockTypesRequest: (state) => {
      state.blockTypesStatus = 'loading';
    },
    getBlockTypesSuccess: (state, res) => {
      state.blockTypesStatus = 'success';
      Vue.set(state, 'blockTypes', [{ name: i18n.t('table.all'), id: null }, ...res]);
      Vue.set(state, 'blockTypesWithoutAll', res);
    },
    getBlockTypesError: (state) => {
      state.blockTypesStatus = 'error';
    },
    clearBlockTypes: (state) => {
      state.blockTypes = [];
      state.blockTypesWithoutAll = [];
      state.blockTypesStatus = '';
    },
  },
  actions: {
    async GET_BLOCK_TYPES({ dispatch, commit }, payload) {
      commit('getBlockTypesRequest');
      await getBlockTypes()
        .then((res) => {
          commit('getBlockTypesSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getBlockTypesError');
          // commit('getBlockTypesSuccess', [
          //   { id: 1, name: 'Regular' },
          //   { id: 2, name: 'Auction' },
          // ]);
        });
    },
  },
  getters: {
    getBlockTypes: (state) => state.blockTypes || [],
    getblockTypesWithoutAll: (state) => state.blockTypesWithoutAll || [],
  },
};
