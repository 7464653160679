import Vue from 'vue';
import {
  getProgramReleases,
  postProgramReleases,
  getProgramReleasesId,
  putProgramReleasesId,
  deleteProgramReleasesId,
  getProgramReleasesDistinctNames,
} from '../../api/apiServices';

export default {
  state: {
    modalEditProgramReleases: '',
    programReleases: '',
    programReleasesStatus: '',
    programReleasesDistinctNames: '',
  },
  mutations: {
    getModalEditProgramReleases(state, payload) {
      Vue.set(state, 'modalEditProgramReleases', payload.data);
    },
    getProgramReleasesRequest: (state) => {
      state.programReleasesStatus = 'loading';
    },
    getProgramReleasesSuccess: (state, res) => {
      state.programReleasesStatus = 'success';
      Vue.set(state, 'programReleases', res);
    },
    getProgramReleasesDistinctNamesSuccess: (state, res) => {
      state.programReleasesStatus = 'success';
      Vue.set(state, 'programReleasesDistinctNames', res);
    },
    getProgramReleasesError: (state) => {
      state.programReleasesStatus = 'error';
    },
    cleanProgramReleases: (state) => {
      state.programReleases = '';
      state.programReleasesDistinctNames = '';
      state.modalEditProgramReleases = '';
      state.programReleasesStatus = '';
    },
  },
  actions: {
    async GET_PROGRAM_RELEASES({ dispatch, commit }, payload) {
      commit('getProgramReleasesRequest');
      await getProgramReleases(payload)
        .then((res) => {
          commit('getProgramReleasesSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getProgramReleasesError');
        });
    },
    async GET_PROGRAM_RELEASES_DISTINCT_NAMES({ dispatch, commit }, payload) {
      commit('getProgramReleasesRequest');
      await getProgramReleasesDistinctNames(payload)
        .then((res) => {
          commit('getProgramReleasesDistinctNamesSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getProgramReleasesError');
        });
    },
    async POST_PROGRAM_RELEASES({ dispatch, commit }, payload) {
      await postProgramReleases(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PROGRAM_RELEASES_ID({ dispatch, commit }, payload) {
      await getProgramReleasesId(payload)
        .then((res) => {
          commit('getModalEditProgramReleases', res.data);
        })
        .catch((e) => {});
    },
    async PUT_PROGRAM_RELEASES({ dispatch, commit }, payload) {
      const id = payload.programReleasesId;
      await putProgramReleasesId(id, payload.formData)
        .then((res) => {
          payload.handler(res?.data);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PROGRAM_RELEASES_ID({ dispatch, commit }, payload) {
      await deleteProgramReleasesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditProgramReleases: (state) => state.modalEditProgramReleases,
    getProgramReleases: (state) => state.programReleases,
    getProgramReleasesDistinctNames: (state) => state.programReleasesDistinctNames || [],
    getProgramReleasesList: (state) => state.programReleases || [],
    getProgramReleasesStatus: (state) => state.programReleasesStatus,
  },
};
