<template>
  <div
    :tabindex="searchable ? -1 : tabindex"
    :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
    class="multiselect"
    @focus="activate()"
    @blur="searchable ? false : deactivate()"
    @keydown.self.down.prevent="pointerForward()"
    @keydown.self.up.prevent="pointerBackward()"
    @keypress.enter.tab.stop.self="addPointerElement($event)"
    @keydown.esc.stop="deactivate()"
  >
    <slot name="caret" :toggle="toggle">
      <div class="multiselect__select" @mousedown.prevent.stop="toggle()"></div>
    </slot>
    <slot name="clear" :search="search"></slot>
    <div
      ref="tags"
      class="multiselect__tags"
      :class="[isThemeHeader === 'true' ? 'white-color' : 'text-color', isThemeHeader === 'true' ? 'multiselect__tags__dark' : '']"
    >
      <slot name="selection" :search="search" :remove="removeElement" :values="visibleValues" :is-open="isOpen">
        <div v-show="visibleValues.length > 0" class="multiselect__tags-wrap">
          <template v-for="(option, index) of visibleValues" @mousedown.prevent>
            <slot name="tag" :option="option" :search="search" :remove="removeElement">
              <span :key="index" class="multiselect__tag">
                <span v-text="getOptionLabel(option)"></span>
                <i
                  aria-hidden="true"
                  tabindex="1"
                  class="multiselect__tag-icon"
                  @keypress.enter.prevent="removeElement(option)"
                  @mousedown.prevent="removeElement(option)"
                ></i>
              </span>
            </slot>
          </template>
        </div>
        <template v-if="internalValue && internalValue.length > limit">
          <slot name="limit">
            <strong class="multiselect__strong" v-text="limitText(internalValue.length - limit)" />
          </slot>
        </template>
      </slot>
      <transition name="multiselect__loading">
        <slot name="loading">
          <div v-show="loading" class="multiselect__spinner" />
        </slot>
      </transition>
      <input
        v-if="searchable"
        :id="id"
        ref="search"
        :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'"
        :name="name"
        :data-automatic="dataAutomatic"
        type="text"
        autocomplete="nope"
        :placeholder="placeholder"
        :style="inputStyle"
        :value="search"
        :disabled="disabled"
        :tabindex="tabindex"
        class="multiselect__input"
        @input="updateSearch($event.target.value)"
        @focus.prevent="activate()"
        @blur.prevent="deactivate()"
        @keydown.esc.stop="deactivate()"
        @keydown.down.prevent="pointerForward()"
        @keydown.up.prevent="pointerBackward()"
        @keypress.enter.prevent.stop.self="addPointerElement($event)"
        @keydown.delete.stop="removeLastElement()"
      />
      <span
        v-if="isSingleLabelVisible"
        class="multiselect__single"
        :class="isThemeHeader === 'true' ? 'multiselect__single__dark' : ''"
        @mousedown.prevent="toggle"
      >
        <slot name="singleLabel" :option="singleValue">
          <template>{{ currentOptionLabel }}</template>
        </slot>
      </span>
      <span v-if="isPlaceholderVisible" class="multiselect__placeholder" @mousedown.prevent="toggle">
        <slot name="placeholder">
          {{ placeholder }}
        </slot>
      </span>
    </div>
    <transition name="multiselect">
      <div
        v-show="isOpen"
        ref="list"
        :class="isThemeHeader === 'true' ? 'multiselect__content-wrapper__dark' : ''"
        class="multiselect__content-wrapper"
        tabindex="-1"
        :style="{ maxHeight: optimizedHeight + 'px' }"
        @focus="activate"
        @mousedown.prevent
      >
        <ul class="multiselect__content" :style="contentStyle">
          <slot name="beforeList"></slot>
          <li v-if="multiple && max === internalValue.length">
            <span class="multiselect__option">
              <slot name="maxElements">{{ $t('multiselect.max') }} {{ max }} {{ $t('multiselect.maxText') }}</slot>
            </span>
          </li>
          <template v-if="!max || internalValue.length < max">
            <li
              v-for="(option, index) of filteredOptions"
              :key="index"
              :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              class="multiselect__element"
            >
              <span
                v-if="!(option && (option.$isLabel || option.$isDisabled))"
                :class="optionHighlight(index, option)"
                class="multiselect__option"
                @click.stop="select(option)"
                @mouseenter.self="pointerSet(index)"
              >
                <slot name="option" :option="option" :search="search">
                  <span>{{ getOptionLabel(option) }}</span>
                </slot>
              </span>
              <span
                v-if="option && (option.$isLabel || option.$isDisabled)"
                :data-select="groupSelect && selectGroupLabelText"
                :data-deselect="groupSelect && deselectGroupLabelText"
                :class="groupHighlight(index, option)"
                class="multiselect__option"
                @mouseenter.self="groupSelect && pointerSet(index)"
                @mousedown.prevent="selectGroup(option)"
              >
                <slot name="option" :option="option" :search="search">
                  <span>{{ getOptionLabel(option) }}</span>
                </slot>
              </span>
            </li>
          </template>
          <li v-show="showNoResults && filteredOptions.length === 0 && search && !loading" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
            <span class="multiselect__option">
              <slot name="noResult" :search="search">{{ $t('multiselect.noElement') }}</slot>
            </span>
          </li>
          <li v-show="showNoOptions && options.length === 0 && !search && !loading" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
            <span class="multiselect__option" style="white-space: normal">
              <slot name="noOptions">{{ $t('multiselect.empty') }}</slot>
            </span>
          </li>
          <slot name="afterList"></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import multiselectMixin from '@/plugins/multiselectMixin';
import pointerMixin from '@/plugins/pointerMixin';

export default {
  name: 'MultiSelect',
  mixins: [multiselectMixin, pointerMixin],
  props: {
    /**
     * name attribute to match optional label element
     * @default ''
     * @type {String}
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * data-automatic attribute to provide id for automatic test
     * @default undefined
     * @type {String}
     */
    'data-automatic': {
      type: String,
      default: undefined,
    },
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectLabel: {
      type: String,
      default: 'wrwerw',
    },
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectGroupLabel: {
      type: String,
      default: 'Press enter to select group',
    },
    /**
     * String to show next to selected option
     * @default 'Selected'
     * @type {String}
     */
    selectedLabel: {
      type: String,
      default: 'Selected',
    },
    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    deselectLabel: {
      type: String,
      default: 'Press enter to remove',
    },
    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    deselectGroupLabel: {
      type: String,
      default: 'Press enter to deselect group',
    },
    /**
     * Decide whether to show pointer labels
     * @default true
     * @type {Boolean}
     */
    showLabels: {
      type: Boolean,
      default: true,
    },
    /**
     * Limit the display of selected options. The rest will be hidden within the limitText string.
     * @default 99999
     * @type {Integer}
     */
    limit: {
      type: Number,
      default: 99999,
    },
    /**
     * Sets maxHeight style value of the dropdown
     * @default 300
     * @type {Integer}
     */
    maxHeight: {
      type: Number,
      default: 300,
    },
    /**
     * Function that process the message shown when selected
     * elements pass the defined limit.
     * @default 'and * more'
     * @param {Int} count Number of elements more than limit
     * @type {Function}
     */
    limitText: {
      type: Function,
      default: (count) => `and ${count} more`,
    },
    /**
     * Set true to trigger the loading spinner.
     * @default False
     * @type {Boolean}
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the multiselect if true.
     * @default false
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Fixed opening direction
     * @default ''
     * @type {String}
     */
    openDirection: {
      type: String,
      default: '',
    },
    /**
     * Shows slot with message about empty options
     * @default true
     * @type {Boolean}
     */
    showNoOptions: {
      type: Boolean,
      default: true,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
    isSingleLabelVisible() {
      return (this.singleValue || this.singleValue === 0) && (!this.isOpen || !this.searchable) && !this.visibleValues.length;
    },
    isPlaceholderVisible() {
      return !this.internalValue.length && (!this.searchable || !this.isOpen);
    },
    visibleValues() {
      return this.multiple ? this.internalValue.slice(0, this.limit) : [];
    },
    singleValue() {
      return this.internalValue[0];
    },
    deselectLabelText() {
      return this.showLabels ? this.deselectLabel : '';
    },
    deselectGroupLabelText() {
      return this.showLabels ? this.deselectGroupLabel : '';
    },
    selectLabelText() {
      return this.showLabels ? this.selectLabel : '';
    },
    selectGroupLabelText() {
      return this.showLabels ? this.selectGroupLabel : '';
    },
    selectedLabelText() {
      return this.showLabels ? this.selectedLabel : '';
    },
    inputStyle() {
      if (this.searchable || (this.multiple && this.value && this.value.length)) {
        // Hide input by setting the width to 0 allowing it to receive focus
        return this.isOpen ? { width: '100%' } : { width: '0', position: 'absolute', padding: '0' };
      }
    },
    contentStyle() {
      return this.options.length ? { display: 'inline-block' } : { display: 'block' };
    },
    isAbove() {
      if (this.openDirection === 'above' || this.openDirection === 'top') {
        return true;
      } else if (this.openDirection === 'below' || this.openDirection === 'bottom') {
        return false;
      } else {
        return this.preferredOpenDirection === 'above';
      }
    },
    showSearchInput() {
      return this.searchable && (this.hasSingleSelectedSlot && (this.visibleSingleValue || this.visibleSingleValue === 0) ? this.isOpen : true);
    },
  },
};
</script>

<style lang="scss">
@use '~/src/assets/sass/_variables' as *;

fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1rem;
  top: 1px;
  width: 48px;
  height: 35px;
  //background: #fff;
  display: block;
}

.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: hsl(354, 70%, 54%) transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect {
  cursor: pointer !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 1rem;
  touch-action: manipulation;
}

.multiselect__single {
  color: #495057 !important;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect__input__dark {
  background-color: $darker-background !important;
}

.multiselect__single__dark {
  background: $darker-background;
  color: #a1a8b8 !important;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  /* min-height: 38px; */
  text-align: left;
  color: #35495e;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  pointer-events: none;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  /* min-height: 20px;
  line-height: 20px; */
  border: none;
  border-radius: 5px;
  background: transparent;
  padding: 0;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
  margin: 0;
  /* font-size: 14px !important; */
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

/* .multiselect__single {
  padding-left: 5px;
} */

.multiselect__tags-wrap {
  /* display: inline; */
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
}

:not(.multiselect--active) .multiselect__tags {
  overflow: hidden;
}

.multiselect__tags {
  /* min-height: 38px; */
  display: block;
  padding: 6px 40px 6px 12px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background: #fff;
  /* font-size: 14px; */
}

.multiselect__tags__dark {
  /* border: 0; */
  border-color: hsl(222, 14%, 30%);
  background-color: $darker-background !important;
  color: #a1a8b8 !important;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  /* margin-right: 10px; */
  color: #fff;
  line-height: 1;
  background: hsl(221, 14%, 30%);
  /* margin-bottom: 5px; */
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tags__dark .multiselect__tag {
  background: hsl(221, 14%, 40%);
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: '×';
  color: hsl(221, 14%, 70%);
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: hsl(221, 14%, 20%);
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}

.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #999999 transparent transparent transparent;
  content: '';
}

.multiselect-sm .multiselect__select:before {
  top: 50%;
}

.multiselect__placeholder {
  display: block;
  color: #aaaaaa;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 1px);
  display: block;
}

.multiselect__tags__dark .multiselect__placeholder {
  color: #6d777b;
}

.multiselect__tags input::-webkit-input-placeholder {
  color: #aaa !important;
}

.multiselect__tags input::-moz-placeholder {
  color: #aaa !important;
}

.multiselect__tags input::-ms-placeholder {
  color: #aaa !important;
}

.multiselect__tags input::placeholder {
  color: #aaa !important;
}

.multiselect__tags__dark input::-webkit-input-placeholder {
  color: #6d777b !important;
}

.multiselect__tags__dark input::-moz-placeholder {
  color: #6d777b !important;
}

/*.multiselect__tags__dark  input::-ms-placeholder {*/
/*    color:#6d777b !important;*/
/*}*/

.multiselect__tags__dark input::placeholder {
  color: #6d777b !important;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  border: 0;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.multiselect__content-wrapper__dark {
  /* background-color: $darker-background; */
  background-color: #2c313a;
  border: 0;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border: 0;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06);
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 10px 12px;
  min-height: 36px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #eff2f5;
  color: #212529;
}

.multiselect__content-wrapper__dark .multiselect__option--highlight {
  background: #3a4652;
  color: white;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  /* background: #41b883; */
  color: white;
}

.multiselect__option--selected {
  background: #e2e5e9;
  color: #35495e;
  font-weight: bold;
}

.multiselect__content-wrapper__dark .multiselect__option--selected {
  background: #5a6674;
  color: #f3f3f3;
  font-weight: bold;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #dc3545;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #dc3545;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  /* background: #ededed; */
  color: #a6a6a6;
}

.multiselect--disabled .multiselect__tags {
  background-color: #e9ecef;
  color: #495057 !important;
}

.multiselect--disabled .multiselect__tags.multiselect__tags__dark {
  background-color: $disabled-input-dark !important;
  color: #a1a8b8 !important;
}

.multiselect--disabled .multiselect__tags .multiselect__tag {
  background: hsl(221, 14%, 80%);
  color: $disabled-input-dark;
}

.multiselect--disabled .multiselect__tags.multiselect__tags__dark .multiselect__tag {
  background: hsl(221, 14%, 30%);
  color: $light-text;
}

.multiselect__option--disabled {
  background: #e9ecef !important;
  color: #495057 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__content-wrapper__dark .multiselect__option--disabled {
  background: $disabled-input-dark !important;
  color: #a1a8b8 !important;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

*[dir='rtl'] .multiselect {
  text-align: right;
}

*[dir='rtl'] .multiselect__select {
  right: auto;
  left: 1px;
}

*[dir='rtl'] .multiselect__tags {
  padding: 8px 8px 0 40px;
}

*[dir='rtl'] .multiselect__content {
  text-align: right;
}

*[dir='rtl'] .multiselect__option:after {
  right: auto;
  left: 0;
}

*[dir='rtl'] .multiselect__clear {
  right: auto;
  left: 12px;
}

*[dir='rtl'] .multiselect__spinner {
  right: auto;
  left: 1px;
}

.multiselect-sm.multiselect {
  min-height: auto;
}

.multiselect-sm .multiselect__input,
.multiselect-sm .multiselect__single {
  /* font-size: 14px; */
  font-size: 0.875rem;
}

.multiselect-sm .multiselect__tags {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-height: auto;
}
:not(.multiselect--active).multiselect-sm .multiselect__tags {
  height: calc(1.5em + 0.5rem + 2px);
}

.multiselect-sm .multiselect__select {
  line-height: 24px;
  height: 28px;
  width: 28px;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>
