import Vue from 'vue';
import { getOrders, postOrders, getOrdersId, putOrdersId, deleteOrdersId, putOrdersRestoreId } from '../../api/apiServices';

export default {
  state: {
    modalEditOrders: '',
    orders: '',
    ordersStatus: '',
  },
  mutations: {
    getModalEditOrders(state, payload) {
      Vue.set(state, 'modalEditOrders', payload.data);
    },
    getOrdersRequest: (state) => {
      state.ordersStatus = 'loading';
    },
    getOrdersSuccess: (state, res) => {
      state.ordersStatus = 'success';
      Vue.set(state, 'orders', res);
    },
    getOrdersError: (state) => {
      state.ordersStatus = 'error';
    },
    clearOrders: (state) => {
      state.orders = '';
      state.ordersStatus = '';
      state.modalEditOrders = '';
    },
  },
  actions: {
    async GET_ORDERS({ dispatch, commit }, payload) {
      commit('getOrdersRequest');
      await getOrders(payload)
        .then((res) => {
          commit('getOrdersSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getOrdersError');
        });
    },
    async POST_ORDERS({ dispatch, commit }, payload) {
      await postOrders(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_ORDERS_ID({ dispatch, commit }, payload) {
      await getOrdersId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditOrders', res.data);
        })
        .catch((e) => {});
    },
    async PUT_ORDERS({ dispatch, commit }, payload) {
      const id = payload.orderId;
      await putOrdersId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_ORDERS_ID({ dispatch, commit }, payload) {
      await deleteOrdersId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_ORDERS_RESTORE_ID({ dispatch, commit }, payload) {
      await putOrdersRestoreId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditOrders: (state) => state.modalEditOrders,
    getOrdersList: (state) => state.orders || [],
    getOrdersStatus: (state) => state.ordersStatus,
  },
};
