import Vue from 'vue';
import { getProjects, postProjects, getProjectsId, getProjectAutomaticMediaplanPrograms, getProjectPremiumPositionDiscounts, putProjectsId, deleteProjectsId, putProjectsRestoreId } from '../../api/apiServices';

export default {
  state: {
    modalEditProjects: '',
    projects: '',
    projectPremiumPositionDiscounts: '',
    projectPremiumPositionDiscountsStatus: '',
    projectAutomaticMediaplanPrograms: '',
    projectAutomaticMediaplanProgramsStatus: '',
    projectsStatus: '',
  },
  mutations: {
    getModalEditProjects(state, payload) {
      Vue.set(state, 'modalEditProjects', payload.data);
    },
    getProjectsRequest: (state) => {
      state.projectsStatus = 'loading';
    },
    getProjectsSuccess: (state, res) => {
      state.projectsStatus = 'success';
      Vue.set(state, 'projects', res);
    },
    getProjectsError: (state) => {
      state.projectsStatus = 'error';
    },
    clearProjects: (state) => {
      Vue.set(state, 'projects', '');
      state.modalEditProjects = '';
      state.projectsStatus = '';
    },

    getProjectAutomaticMediaplanProgramsRequest: (state) => {
      state.projectAutomaticMediaplanProgramsStatus = 'loading';
    },
    getProjectAutomaticMediaplanProgramsSuccess: (state, res) => {
      state.projectAutomaticMediaplanProgramsStatus = 'success';
      Vue.set(state, 'projectAutomaticMediaplanPrograms', res);
    },
    getProjectAutomaticMediaplanProgramsError: (state) => {
      state.projectAutomaticMediaplanProgramsStatus = 'error';
    },
    clearProjectAutomaticMediaplanPrograms: (state) => {
      Vue.set(state, 'projectAutomaticMediaplanPrograms', '');
      state.projectAutomaticMediaplanProgramsStatus = '';
    },

    getProjectPremiumPositionDiscountsRequest: (state) => {
      state.projectPremiumPositionDiscountsStatus = 'loading';
    },
    getProjectPremiumPositionDiscountsSuccess: (state, res) => {
      state.projectPremiumPositionDiscountsStatus = 'success';
      Vue.set(state, 'projectPremiumPositionDiscounts', res);
    },
    getProjectPremiumPositionDiscountsError: (state) => {
      state.projectPremiumPositionDiscountsStatus = 'error';
    },
    clearProjectPremiumPositionDiscounts: (state) => {
      Vue.set(state, 'projectPremiumPositionDiscounts', '');
      state.projectPremiumPositionDiscountsStatus = '';
    },
  },
  actions: {
    async GET_PROJECTS({ dispatch, commit }, payload) {
      commit('getProjectsRequest');
      await getProjects(payload)
        .then((res) => {
          commit('getProjectsSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getProjectsError');
        });
    },
    async POST_PROJECTS({ dispatch, commit }, payload) {
      await postProjects(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_PROJECTS_ID({ dispatch, commit }, payload) {
      await getProjectsId(payload.id, payload.data)
        .then((res) => {
          commit('getModalEditProjects', res.data);
        })
        .catch((e) => {});
    },
    async GET_PROJECT_AUTOMATIC_MEDIAPLAN_PROGRAMS({ dispatch, commit }, payload) {
      commit('getProjectAutomaticMediaplanPrograms');
      await getProjectAutomaticMediaplanPrograms(payload.id, payload.data)
        .then((res) => {
          commit('getProjectAutomaticMediaplanProgramsSuccess', res.data);
        })
        .catch((e) => {
          commit('getProjectAutomaticMediaplanProgramsError');
        });
    },
    async GET_PROJECT_PREMIUM_POSITION_DISCOUNTS({ dispatch, commit }, payload) {
      commit('getProjectPremiumPositionDiscountsRequest');
      await getProjectPremiumPositionDiscounts(payload.id)
        .then((res) => {
          commit('getProjectPremiumPositionDiscountsSuccess', res.data);
        })
        .catch((e) => {
          commit('getProjectPremiumPositionDiscountsError');
        });
    },
    async PUT_PROJECTS({ dispatch, commit }, payload) {
      const id = payload.projectsId;
      await putProjectsId(id, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_PROJECTS_ID({ dispatch, commit }, payload) {
      await deleteProjectsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_PROJECTS_RESTORE_ID({ dispatch, commit }, payload) {
      await putProjectsRestoreId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditProjects: (state) => state.modalEditProjects,
    //getProjects: (state) => state.projects,
    getProjectAutomaticMediaplanPrograms: (state) => state.projectAutomaticMediaplanPrograms,
    getProjectPremiumPositionDiscounts: (state) => state.projectPremiumPositionDiscounts,
    getProjectsList: (state) => state.projects || [],
    getProjectsStatus: (state) => state.projectsStatus,
  },
};
