import Vue from 'vue';
import {
  getCommercialVersionTypes,
  postCommercialVersionTypes,
  getCommercialVersionTypesId,
  putCommercialVersionTypesId,
  deleteCommercialVersionTypesId,
} from '../../api/apiServices';

export default {
  state: {
    modalEditCommercialVersionTypes: '',
    commercialVersionTypes: '',
    commercialVersionTypesStatus: '',
  },
  mutations: {
    getModalEditCommercialVersionTypes(state, payload) {
      Vue.set(state, 'modalEditCommercialVersionTypes', payload.data);
    },
    getCommercialVersionTypesRequest: (state) => {
      state.commercialVersionTypesStatus = 'loading';
    },
    getCommercialVersionTypesSuccess: (state, res) => {
      state.commercialVersionTypesStatus = 'success';
      Vue.set(state, 'commercialVersionTypes', res);
    },
    getCommercialVersionTypesError: (state) => {
      state.commercialVersionTypesStatus = 'error';
    },
    clearCommVerTypesList: (state) => {
      state.commercialVersionTypesStatus = '';
      state.modalEditCommercialVersionTypes = '';
      Vue.set(state, 'commercialVersionTypes', '');
    },
  },
  actions: {
    async GET_COMM_VER_TYPES({ dispatch, commit }, payload) {
      commit('getCommercialVersionTypesRequest');
      await getCommercialVersionTypes(payload)
        .then((res) => {
          commit('getCommercialVersionTypesSuccess', res.data);
        })
        .catch((e) => {
          commit('getCommercialVersionTypesError');
        });
    },
    async POST_COMM_VER_TYPES({ dispatch, commit }, payload) {
      const { name } = payload.formData;

      await postCommercialVersionTypes({
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_COMM_VER_TYPES_ID({ dispatch, commit }, payload) {
      await getCommercialVersionTypesId(payload)
        .then((res) => {
          commit('getModalEditCommercialVersionTypes', res.data);
        })
        .catch((e) => {});
    },
    async PUT_COMM_VER_TYPES({ dispatch, commit }, payload) {
      const { id, name } = payload.formData;
      await putCommercialVersionTypesId(id, {
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_COMM_VER_TYPES_ID({ dispatch, commit }, payload) {
      await deleteCommercialVersionTypesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditCommercialVersionTypes: (state) => state.modalEditCommercialVersionTypes,
    getCommercialVersionTypes: (state) => state.commercialVersionTypes,
    getCommercialVersionTypesStatus: (state) => state.commercialVersionTypesStatus,
  },
};
