import Vue from 'vue';
import { getMarketing, getExportMarketing } from '../../api/apiServices';

export default {
  state: {
    marketingGrid: '',
    marketingStatus: '',
  },
  mutations: {
    getMarketingRequest: (state) => {
      state.marketingStatus = 'loading';
    },
    getMarketingSuccess: (state, res) => {
      state.marketingStatus = 'success';
      Vue.set(state, 'marketingGrid', res);
    },
    getMarketingError: (state) => {
      state.marketingStatus = 'error';
    },
    clearMarketing: (state) => {
      Vue.set(state, 'marketingStatus', '');
      Vue.set(state, 'marketingGrid', '');
    },
  },
  actions: {
    async GET_MARKETING({ dispatch, commit }, payload) {
      commit('getMarketingRequest');
      await getMarketing(payload.data, payload.date)
        .then((res) => {
          commit('getMarketingSuccess', res.data);
        })
        .catch((e) => {
          commit('getMarketingError');
        });
    },
    async GET_EXPORT_MARKETING({ dispatch, commit }, payload) {
      await getExportMarketing(payload.id, payload.params)
        .then((res) => {
          payload.handler(res);
        })
        .catch((e) => {});
    },
  },
  getters: {
    getMarketing: (state) => state.marketingGrid,
    getMarketingStatus: (state) => state.marketingStatus,
  },
};
