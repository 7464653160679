import Vue from 'vue';
import { getChannels, postChannels, getChannelsId, putChannelsId, deleteChannelsId, getChannelsSettings, putRestoreChannelsId } from '../../api/apiServices';

export default {
  state: {
    modalEditChannels: '',
    channels: null,
    channel: null,
    channelsStatus: '',
    channelsEdit: '',
  },
  mutations: {
    getModalEditChannels(state, payload) {
      Vue.set(state, 'modalEditChannels', payload.data);
    },
    getChannelsRequest: (state) => {
      state.channelsStatus = 'loading';
    },
    getChannelsSuccess: (state, res) => {
      state.channelsStatus = 'success';
      Vue.set(state, 'channels', res);
    },
    getChannelsError: (state) => {
      state.channelsStatus = 'error';
    },
    getChannelsEditSuccess: (state, res) => {
      Vue.set(state, 'channelsEdit', res);
    },

    getChannelSuccess: (state, res) => {
      Vue.set(state, 'channel', res);
    },

    clearChannelsList: (state) => {
      state.channelsStatus = '';
      Vue.set(state, 'channel', null);
      Vue.set(state, 'channels', null);
      state.modalEditChannels = '';
      state.channelsEdit = '';
    },
  },
  actions: {
    async GET_CHANNELS_SETTINGS({ dispatch, commit }, payload) {
      await getChannelsSettings(payload.id, payload.year)
        .then((res) => {
          commit('getChannelsEditSuccess', Object.values(res.data.data[0]));
        })
        .catch((err) => {});
    },
    async GET_CHANNELS({ dispatch, commit }, payload) {
      commit('getChannelsRequest');
      await getChannels(payload)
        .then((res) => {
          commit('getChannelsSuccess', res.data);
        })
        .catch((e) => {
          commit('getChannelsError');
        });
    },
    async GET_CHANNEL({ dispatch, commit }, payload) {
      await getChannels(payload)
        .then((res) => {
          commit('getChannelSuccess', res.data.data);
        })
        .catch((e) => {
          //commit('getChannelsError')
        });
    },
    async POST_CHANNELS({ dispatch, commit }, payload) {
      await postChannels(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_CHANNELS_ID({ dispatch, commit }, payload) {
      await getChannelsId(payload)
        .then((res) => {
          commit('getModalEditChannels', res.data);
        })
        .catch((e) => {});
    },
    async PUT_CHANNELS({ dispatch, commit }, payload) {
      const { id } = payload.formData;
      await putChannelsId(id, payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_CHANNELS_ID({ dispatch, commit }, payload) {
      await deleteChannelsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_RESTORE_CHANNELS({ dispatch, commit }, payload) {
      await putRestoreChannelsId(payload.id)
        .then((res) => payload.handler(res))
        .catch((err) => payload.handlerError(err));
    },
  },
  getters: {
    getModalEditChannels: (state) => state.modalEditChannels,
    getChannels: (state) => state.channels,
    getChannel: (state) => state.channel || [],
    getChannelsList: (state) => (state.channels ? state.channels.data : []),
    getChannelsEdit: (state) => state.channelsEdit,
    getChannelsStatus: (state) => state.channelsStatus,
  },
};
