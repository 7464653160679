import Vue from 'vue';
import { getMeasurements, postMeasurements, getMeasurementsId, putMeasurementsId, deleteMeasurementsId } from '../../api/apiServices';

export default {
  state: {
    modalEditMeasurements: '',
    measurements: null,
    measurementsStatus: '',
  },
  mutations: {
    getModalEditMeasurements(state, payload) {
      Vue.set(state, 'modalEditMeasurements', payload.data);
    },
    getMeasurementsRequest: (state) => {
      state.measurementsStatus = 'loading';
    },
    getMeasurementsSuccess: (state, res) => {
      state.measurementsStatus = 'success';
      Vue.set(state, 'measurements', res);
    },
    getMeasurementsError: (state) => {
      state.measurementsStatus = 'error';
    },
    clearMeasurementsList: (state) => {
      state.measurementsStatus = '';
      state.modalEditMeasurements = '';
      Vue.set(state, 'measurements', '');
    },
  },
  actions: {
    async GET_MEASUREMENTS({ dispatch, commit }, payload) {
      commit('getMeasurementsRequest');
      await getMeasurements(payload)
        .then((res) => {
          commit('getMeasurementsSuccess', res.data);
        })
        .catch((e) => {
          commit('getMeasurementsError');
        });
    },
    async POST_MEASUREMENTS({ dispatch, commit }, payload) {
      const { name } = payload.formData;

      await postMeasurements({
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_MEASUREMENTS_ID({ dispatch, commit }, payload) {
      await getMeasurementsId(payload)
        .then((res) => {
          commit('getModalEditMeasurements', res.data);
        })
        .catch((e) => {});
    },
    async PUT_MEASUREMENTS_ID({ dispatch, commit }, payload) {
      const { id, name } = payload.formData;

      await putMeasurementsId(id, {
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_MEASUREMENTS_ID({ dispatch, commit }, payload) {
      await deleteMeasurementsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditMeasurements: (state) => state.modalEditMeasurements,
    getMeasurements: (state) => state.measurements,
    getMeasurementsList: (state) => (state.measurements && state.measurements.data) || [],
    getMeasurementsStatus: (state) => state.measurementsStatus,
  },
};
