import Vue from 'vue';
import { putSalehousesSettings, getSalehousesSettings } from '../../api/apiServices';

export default {
  state: {
    salehousesSettings: null,
    salehousesSettingsStatus: '',
  },
  mutations: {
    getSalehousesSettingsRequest: (state) => {
      state.salehousesStatus = 'loading';
    },
    getSalehousesSettingsSuccess: (state, res) => {
      state.salehousesStatus = 'success';
      Vue.set(state, 'salehousesSettings', res);
    },
    getSalehousesSettingsError: (state) => {
      state.salehousesSettingsStatus = 'error';
    },
    clearSalesHousesList: (state) => {
      state.salehousesStatus = '';
      state.modalEditSalehouses = '';
      Vue.set(state, 'salehousesSettings', '');
    },
  },
  actions: {
    async GET_SALEHOUSES_SETTINGS({ dispatch, commit }, payload) {
      commit('getSalehousesSettingsRequest');
      await getSalehousesSettings(payload)
        .then((res) => {
          commit('getSalehousesSettingsSuccess', res.data);
        })
        .catch((e) => {
          commit('getSalehousesSettingsError');
        });
    },

    async PUT_SALEHOUSES_SETTINGS({ dispatch, commit }, payload) {
      const { id, is_equals_enabled } = payload.formData;
      await putSalehousesSettings(id, {
        is_equals_enabled,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getSalehousesSettings: (state) => (state.salehousesSettings && state.salehousesSettings.data) || [],
    getSalehousesSettingsStatus: (state) => state.salehousesSettingsStatus,
  },
};
