<template>
  <b-spinner
    v-if="loading === 'loading'"
    class="loader"
    :type="automaton ? 'grow' : undefined"
    :class="{ 'loader-in-corner': floatInCorner, automaton: automaton }"
    :title="automaton ? $t('table.automatonsInQueue') : undefined"
  >
  </b-spinner>
  <h5 v-else-if="loading === 'error'" class="text-center">{{ $t('table.error') }}</h5>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
export default {
  name: 'SpinnerLoader',
  components: { BSpinner },
  props: {
    loading: {
      type: String,
      default: undefined,
    },
    floatInCorner: {
      type: Boolean,
    },
    automaton: {
      type: Boolean,
    },
  },
};
</script>

<style lang="sass">
.spinner-border,
.spinner-grow
  &.loader
    display: flex
    justify-content: center
    align-items: center
    margin: 50px auto
    width: 3rem
    height: 3rem
    color: #4b4b5a
    &.loader-in-corner
      margin: 0
      position: fixed
      right: 16px
      top: 16px
      z-index: 10000
      width: 1rem
      height: 1rem

    &.automaton
      margin: 0
      position: fixed
      right: 8px
      top: 8px
      z-index: 10001
      width: 8px
      height: 8px
      color: #dc3545
      animation-duration: 1s

[data-color-scheme="dark"]
  .spinner-border,
  .spinner-grow
    &.loader:not(.automaton)
      color: hsl(222, 14%, 70%)
</style>
