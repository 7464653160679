import Vue from 'vue';
import { i18n } from '@/i18n';
import { getPlacementType } from '../../api/apiServices';

export default {
  state: {
    placementType: [],
    placementTypeStatus: '',
    placementTypeWithoutAll: '',
  },
  mutations: {
    getPlacementTypeRequest: (state) => {
      state.placementTypeStatus = 'loading';
    },
    getPlacementTypeSuccess: (state, res) => {
      state.placementTypeStatus = 'success';
      Vue.set(state, 'placementTypeWithoutAll', res);
      Vue.set(state, 'placementType', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getPlacementTypeError: (state) => {
      state.placementTypeStatus = 'error';
    },
    clearPlacementType: (state) => {
      state.placementType = [];
      state.placementTypeStatus = '';
      state.placementTypeWithoutAll = '';
    },
  },
  actions: {
    async GET_PLACEMENT_TYPE({ dispatch, commit }, payload) {
      commit('getPlacementTypeRequest');
      await getPlacementType()
        .then((res) => {
          commit('getPlacementTypeSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getPlacementTypeError');
        });
    },
  },
  getters: {
    getPlacementType: (state) => state.placementType || [],
    getPlacementTypeStatus: (state) => state.placementTypeStatus,
    getPlacementTypeWithoutAll: (state) => state.placementTypeWithoutAll || [],
  },
};
