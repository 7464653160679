import Vue from 'vue';
import { i18n } from '@/i18n';
import { getHoldings, getHoldingsId, putHoldingsId, postHoldings, deleteHoldingsId } from '../../api/apiServices';

export default {
  state: {
    modalEditHoldings: '',
    holdings: '',
    holdingsStatus: '',
    holdingsList: '',
    holdingsListWithAll: '',
  },
  mutations: {
    getModalEditHoldings(state, payload) {
      Vue.set(state, 'modalEditHoldings', payload.data);
    },
    getHoldingsListSuccess: (state, res) => {
      Vue.set(state, 'holdingsList', res);
      Vue.set(state, 'holdingsListWithAll', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getHoldingsRequest: (state) => {
      state.holdingsStatus = 'loading';
    },
    getHoldingsSuccess: (state, res) => {
      state.holdingsStatus = 'success';
      Vue.set(state, 'holdings', res);
    },
    getHoldingsError: (state) => {
      state.holdingsStatus = 'error';
    },

    clearHoldingsList: (state) => {
      state.modalEditHoldings = '';
      state.holdingsStatus = '';
      Vue.set(state, 'holdings', '');
      Vue.set(state, 'holdingsList', '');
      Vue.set(state, 'holdingsListWithAll', '');
    },
  },
  actions: {
    async GET_HOLDINGS({ dispatch, commit }, payload) {
      commit('getHoldingsRequest');
      await getHoldings(payload)
        .then((res) => {
          commit('getHoldingsSuccess', res.data);
          commit('getHoldingsListSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getHoldingsError');
        });
    },
    async POST_HOLDINGS({ dispatch, commit }, payload) {
      const { name, channels } = payload.formData;

      await postHoldings({
        name,
        channels,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_HOLDINGS_ID({ dispatch, commit }, payload) {
      await getHoldingsId(payload)
        .then((res) => {
          commit('getModalEditHoldings', res.data);
        })
        .catch((e) => {});
    },
    async PUT_HOLDINGS({ dispatch, commit }, payload) {
      const { id, name, channels } = payload.formData;
      await putHoldingsId(id, {
        name,
        channels,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_HOLDINGS_ID({ dispatch, commit }, payload) {
      await deleteHoldingsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditHoldings: (state) => state.modalEditHoldings,
    getHoldings: (state) => state.holdings,
    getHoldingsList: (state) => state.holdingsList || [],
    getHoldingsListWithAll: (state) => state.holdingsListWithAll || [],
    getHoldingsStatus: (state) => state.holdingsStatus,
  },
};
