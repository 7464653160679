//Check permissions function for router
export default {
  checkPermissions(...permissions) {
    if (localStorage.getItem('currentUserPermissions')) {
      try {
        const currentUserPermissions = localStorage.getItem('currentUserPermissions').split(',');
        return permissions.every((v) => currentUserPermissions.includes(v));
      } catch (e) {
        localStorage.removeItem('currentUserPermissions');
        return false;
      }
    } else {
      return false;
    }
  },
};
