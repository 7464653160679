import Vue from 'vue';
import { getClasses, postClasses, getClassesId, putClassesId, deleteClassesId } from '../../api/apiServices';

export default {
  state: {
    modalEditClasses: '',
    classes: '',
    classesStatus: '',
  },
  mutations: {
    getModalEditClasses(state, payload) {
      Vue.set(state, 'modalEditClasses', payload.data);
    },
    getClassesRequest: (state) => {
      state.classesStatus = 'loading';
    },
    getClassesSuccess: (state, res) => {
      state.classesStatus = 'success';
      Vue.set(state, 'classes', res);
    },
    getClassesError: (state) => {
      state.classesStatus = 'error';
    },

    clearClassesList: (state) => {
      state.classesStatus = '';
      Vue.set(state, 'classes', '');
      state.modalEditClasses = '';
    },
  },
  actions: {
    async GET_CLASSES({ dispatch, commit }, payload) {
      commit('getClassesRequest');
      await getClasses(payload)
        .then((res) => {
          commit('getClassesSuccess', res.data);
        })
        .catch((e) => {
          commit('getClassesError');
        });
    },
    async POST_CLASSES({ dispatch, commit }, payload) {
      await postClasses(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_CLASSES_ID({ dispatch, commit }, payload) {
      await getClassesId(payload)
        .then((res) => {
          commit('getModalEditClasses', res.data);
        })
        .catch((e) => {});
    },
    async PUT_CLASSES_ID({ dispatch, commit }, payload) {
      const { id, name, brand_groups } = payload.formData;
      await putClassesId(id, {
        name,
        brand_groups,
      })
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_CLASSES_ID({ dispatch, commit }, payload) {
      await deleteClassesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditClasses: (state) => state.modalEditClasses,
    getClasses: (state) => state.classes,
    getClassesList: (state) => state.classes?.data || [],
    getClassesStatus: (state) => state.classesStatus,
  },
};
