import Vue from 'vue';
import { getGroup, postGroup, putGroupId, deleteGroupId } from '../../api/apiServices';

export default {
  state: {
    group: '',
    groupStatus: '',
  },
  mutations: {
    getGroupRequest: (state) => {
      state.groupStatus = 'loading';
    },
    getGroupSuccess: (state, res) => {
      state.groupStatus = 'success';
      Vue.set(state, 'group', res);
    },
    getGroupError: (state) => {
      state.groupStatus = 'error';
    },
    clearGroups: (state) => {
      state.group = '';
      state.groupStatus = '';
    },
  },
  actions: {
    async GET_GROUP({ dispatch, commit }, payload) {
      commit('getGroupRequest');
      await getGroup(payload)
        .then((res) => {
          commit('getGroupSuccess', res.data);
        })
        .catch((e) => {
          commit('getGroupError');
        });
    },
    async POST_GROUP({ dispatch, commit }, payload) {
      await postGroup(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async PUT_GROUP_ID({ dispatch, commit }, payload) {
      const { id, name, brand_class_id } = payload.formData;
      await putGroupId(id, {
        name,
        brand_class_id,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_GROUP_ID({ dispatch, commit }, payload) {
      await deleteGroupId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getGroup: (state) => state.group,
    getGroupList: (state) => state.group?.data || [],
    getGroupStatus: (state) => state.groupStatus,
  },
};
