import Vue from 'vue';
import { getDiscountTypes, postDiscountTypes, getDiscountTypesId, putDiscountTypesId, deleteDiscountTypesId } from '../../api/apiServices';

export default {
  state: {
    modalEditDiscountTypes: '',
    discountTypes: '',
    discountTypesStatus: '',
  },
  mutations: {
    getModalEditDiscountTypes(state, payload) {
      Vue.set(state, 'modalEditDiscountTypes', payload.data);
    },
    getDiscountTypesRequest: (state) => {
      state.discountTypesStatus = 'loading';
    },
    getDiscountTypesSuccess: (state, res) => {
      state.discountTypesStatus = 'success';
      Vue.set(state, 'discountTypes', res);
    },
    getDiscountTypesError: (state) => {
      state.discountTypesStatus = 'error';
    },
    clearDiscountTypesList: (state) => {
      state.discountTypesStatus = '';
      state.modalEditDiscountTypes = '';
      Vue.set(state, 'discountTypes', '');
    },
  },
  actions: {
    async GET_DISCOUNT_TYPES({ dispatch, commit }, payload) {
      commit('getDiscountTypesRequest');
      await getDiscountTypes(payload)
        .then((res) => {
          commit('getDiscountTypesSuccess', res.data);
        })
        .catch((e) => {
          commit('getDiscountTypesError');
        });
    },
    async POST_DISCOUNT_TYPES({ dispatch, commit }, payload) {
      const { name, percent, to, from, channel_id, type } = payload.formData;

      await postDiscountTypes({
        name,
        percent,
        to,
        from,
        channel_id,
        type,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_DISCOUNT_TYPES_ID({ dispatch, commit }, payload) {
      await getDiscountTypesId(payload)
        .then((res) => {
          commit('getModalEditDiscountTypes', res.data);
        })
        .catch((e) => {});
    },
    async PUT_DISCOUNT_TYPES({ dispatch, commit }, payload) {
      const { id, name, percent, from, to, channel_id } = payload.formData;
      await putDiscountTypesId(id, {
        name,
        percent,
        from,
        to,
        channel_id,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_DISCOUNT_TYPES_ID({ dispatch, commit }, payload) {
      await deleteDiscountTypesId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditDiscountTypes: (state) => state.modalEditDiscountTypes,
    getDiscountTypes: (state) => state.discountTypes,
    getDiscountTypesStatus: (state) => state.discountTypesStatus,
  },
};
