import Vue from 'vue';
import { getGenres, postGenres, getGenresId, putGenresId, deleteGenresId } from '../../api/apiServices';

export default {
  state: {
    modalEditGenres: '',
    genres: '',
    genresStatus: '',
  },
  mutations: {
    getModalEditGenres(state, payload) {
      Vue.set(state, 'modalEditGenres', payload.data);
    },
    getGenresRequest: (state) => {
      state.genresStatus = 'loading';
    },
    getGenresSuccess: (state, res) => {
      state.genresStatus = 'success';
      Vue.set(state, 'genres', res);
    },
    getGenresError: (state) => {
      state.genresStatus = 'error';
    },
    clearGenresList: (state) => {
      state.genresStatus = '';
      state.modalEditGenres = '';
      Vue.set(state, 'genres', '');
    },
  },
  actions: {
    async GET_GENRES({ dispatch, commit }, payload) {
      commit('getGenresRequest');
      await getGenres(payload)
        .then((res) => {
          commit('getGenresSuccess', res.data);
        })
        .catch((e) => {
          commit('getGenresError');
        });
    },
    async POST_GENRES({ dispatch, commit }, payload) {
      const { name } = payload.formData;

      await postGenres({
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_GENRES_ID({ dispatch, commit }, payload) {
      await getGenresId(payload)
        .then((res) => {
          commit('getModalEditGenres', res.data);
        })
        .catch((e) => {});
    },
    async PUT_GENRES({ dispatch, commit }, payload) {
      const { id, name } = payload.formData;
      await putGenresId(id, {
        name,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_GENRES_ID({ dispatch, commit }, payload) {
      await deleteGenresId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditGenres: (state) => state.modalEditGenres,
    getGenres: (state) => state.genres,
    getGenresList: (state) => state.genres?.data || [],
    getGenresStatus: (state) => state.genresStatus,
  },
};
