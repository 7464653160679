<template>
  <div :class="{ 'bg-dark': isThemeHeader === 'true' }" style="min-height: 100vh">
    <LayoutHeader />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutHeader from '@/components/LayoutHeader';

export default {
  name: 'DefaultLayout',
  components: { LayoutHeader },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
  },
};
</script>

<style lang="scss">
</style>

