import Vue from 'vue';
import { i18n } from '@/i18n';
import { getBrands, postBrands, putBrandsId, deleteBrandsId, getBrandsId } from '../../api/apiServices';

export default {
  state: {
    brands: '',
    brandsStatus: '',
    brandsEdit: '',
    brandsList: '',
    brandsListWithAll: '',
    brandsListOther: '',
  },
  mutations: {
    getBrandsRequest: (state) => {
      state.brandsStatus = 'loading';
    },
    getBrands2Success: (state, res) => {
      state.brandsStatus = 'success';
      Vue.set(state, 'brandsList', res);
      Vue.set(state, 'brandsListWithAll', [{ name: i18n.t('table.all'), id: null }, ...res]);
    },
    getBrandsSuccess: (state, res) => {
      state.brandsStatus = 'success';
      Vue.set(state, 'brands', res);
    },
    getBrandsListSuccess: (state, res) => {
      state.brandsStatus = 'success';
      Vue.set(state, 'brandsListOther', res);
    },
    getBrandsError: (state) => {
      state.brandsStatus = 'error';
    },

    clearBrandsList: (state) => {
      Vue.set(state, 'brandsList', '');
      Vue.set(state, 'brandsListWithAll', '');
      state.brandsEdit = '';
      state.brandsStatus = '';
      state.brands = '';
      state.brandsListOther = '';
    },

    getModalEditBrands: (state, res) => {
      Vue.set(state, 'brandsEdit', res);
    },
  },
  actions: {
    async GET_BRANDS({ dispatch, commit }, payload) {
      commit('getBrandsRequest');
      await getBrands(payload)
        .then((res) => {
          //commit('getBrandsSuccess', res.data);
          commit('getBrands2Success', res.data.data);
        })
        .catch((e) => {
          commit('getBrandsError');
        });
    },
    async GET_BRANDS_FIRST({ dispatch, commit }, payload) {
      commit('getBrandsRequest');
      await getBrands(payload)
        .then((res) => {
          commit('getBrandsSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getBrandsError');
        });
    },
    async GET_BRANDS_OTHER({ dispatch, commit }, payload) {
      commit('getBrandsRequest');
      await getBrands(payload)
        .then((res) => {
          commit('getBrandsListSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getBrandsError');
        });
    },
    async POST_BRANDS({ dispatch, commit }, payload) {
      const { name, advertiser_id, brand_group_id, brand_classes_id } = payload.formData;

      await postBrands({
        name,
        advertiser_id,
        brand_group_id,
        brand_classes_id,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_BRANDS_ID({ dispatch, commit }, payload) {
      await getBrandsId(payload)
        .then((res) => {
          commit('getModalEditBrands', res.data);
        })
        .catch((e) => {});
    },
    async PUT_BRANDS_ID({ dispatch, commit }, payload) {
      const { id, name, advertiser_id, brand_group_id, brand_classes_id } = payload.formData;
      await putBrandsId(id, {
        name,
        advertiser_id,
        brand_group_id,
        brand_classes_id,
      })
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_BRANDS_ID({ dispatch, commit }, payload) {
      await deleteBrandsId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getBrands: (state) => state.brands,
    getBrandsList: (state) => state.brandsList || [],
    getBrandsListWithAll: (state) => state.brandsListWithAll || [],
    getBrandsListOther: (state) => state.brandsListOther,
    getBrandsEdit: (state) => state.brandsEdit,
    getBrandsStatus: (state) => state.brandsStatus,
  },
};
