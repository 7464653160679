export default {
  data() {
    return {
      FEATURES: {
        AUCTIONS: process.env.VUE_APP_ENABLE_AUCTIONS === 'TRUE',
        RETROBONUS: process.env.VUE_APP_ENABLE_RETROBONUS === 'TRUE',
        HOLDINGS: process.env.VUE_APP_ENABLE_HOLDINGS === 'TRUE',
        EXPORT_RIGHT: process.env.VUE_APP_ENABLE_EXPORT_RIGHT === 'TRUE',
        SET_BROADCAST_TIME: process.env.VUE_APP_ENABLE_SET_BROADCAST_TIME === 'TRUE',
        EQUALS: process.env.VUE_APP_ENABLE_EQUALS === 'TRUE',
        SUBCHANNELS: process.env.VUE_APP_ENABLE_SUBCHANNELS === 'TRUE',
      },
    };
  },
};
