import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserPermissions: 'getCurrentUserPermissions',
      currentUserRole: 'getCurrentUserRole',
    }),
  },
  methods: {
    $checkPermissions(...permissions) {
      if (!permissions) return true; //if argument is empty
      if (this.currentUserPermissions) {
        return permissions.every((v) => this.currentUserPermissions.includes(v));
      } else {
        return false;
      }
    },
    $checkRole(...roles) {
      if (this.currentUserRole) {
        return roles.some((role) => role === this.currentUserRole);
      } else {
        return false;
      }
    },
  },
};
