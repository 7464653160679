import Vue from 'vue';
import {
  getPermissions,
  putPermissionsId,
  getUserPermissionsId,
  putUserPermissionsId,
  postUserPermissionsId,
  postPermissionsPermissionsAdd,
  postPermissionsPermissionsRevoke,
} from '../../api/apiServices';

export default {
  state: {
    userPermissions: '',
    permissions: '',
    permissionsStatus: '',
  },
  mutations: {
    setUserPermissions(state, res) {
      Vue.set(state, 'userPermissions', res);
    },
    getPermissionsRequest: (state) => {
      state.permissionsStatus = 'loading';
    },
    getPermissionsSuccess: (state, res) => {
      state.permissionsStatus = 'success';
      Vue.set(state, 'permissions', res);
    },
    getPermissionsError: (state) => {
      state.permissionsStatus = 'error';
    },
    clearPermissions: (state) => {
      state.permissions = '';
    },
  },
  actions: {
    async GET_PERMISSIONS({ dispatch, commit }, payload) {
      commit('getPermissionsRequest');
      await getPermissions(payload)
        .then((res) => {
          commit('getPermissionsSuccess', res.data.data);
        })
        .catch((e) => {
          commit('getPermissionsError');
        });
    },

    async PUT_ROLE_PERMISSIONS({ dispatch, commit }, payload) {
      await putPermissionsId(payload.roleId, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async GET_USER_PERMISSIONS_ID({ dispatch, commit }, payload) {
      await getUserPermissionsId(payload.userId, payload.data)
        .then((res) => {
          const userPerms = res.data.map((item) => item.name);
          commit('setUserPermissions', userPerms);
          //localStorage.setItem('userPermissions', userPerms);
        })
        .catch((e) => {});
    },

    async PUT_USER_PERMISSIONS({ dispatch, commit }, payload) {
      await putUserPermissionsId(payload.userId, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async POST_USER_ROLE({ dispatch, commit }, payload) {
      await postUserPermissionsId(payload.userId, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async POST_MODEL_PERMISSIONS({ dispatch, commit }, payload) {
      await postPermissionsPermissionsAdd(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async POST_REVOKE_PERMISSIONS({ dispatch, commit }, payload) {
      await postPermissionsPermissionsRevoke(payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getUserPermissions: (state) => state.userPermissions || [],
    getPermissions: (state) => state.permissions || [],
    getPermissionsStatus: (state) => state.permissionsStatus,
  },
};
